import React, { useState } from 'react';
import { Button, Card,Col,Form, Row  } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

const CambiarContrasenaPage = () => {
    const navigate = useNavigate();

    const [contrasena, setContrasena] = useState({
        pass: "",
        confirmpass: "",
    });

    const [tocado, setTocado] = useState({
        pass: false,
        confirmpass: false,
    });

    const handleChange = (e) => {
    // Actualiza el estado de la categoría al escribir en los campos del formulario
    const { name, value } = e.target;
    setContrasena({ ...contrasena, [name]: value });
    setTocado({ ...tocado, [name]: true });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
            title:'Advertencia',
            text:'No se encontro un token de autorizaciòn',
            icon:'error'
            });
            return;
        }

        const datosContrasena = {
            password: contrasena.pass,
            confirmPassword: contrasena.confirmpass,
        };

        axios.post('https://api.meycisstore.com/users/changepassword', datosContrasena, {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
            },
        })
        .then(response => {
            setContrasena(response.data);
        Swal.fire({
            title:'Confirmacion',
            text:'Se cambio la contraseña correctamente',
            icon:'success'
        }).then(()=>{
                navigate('/admin/dashboard');
            });
        })
        .catch(error => {
            Swal.fire({
                title:'Advertencia',
                text:`Error al cambiar la contraseña: ${error} `,
                icon:'error'
            });
        })
    };


  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Cambiar Contraseña</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className='h-100 d-flex justify-content-center'>
            <Col sm={12} md={4} >
                <Card className='h-100'>
                    <Card.Body>
                        <Form className='h-100 d-flex flex-column' onSubmit={handleSubmit}>
                            <div>
                                <Form.Group className="mb-3" controlId="formContrasena">
                                <Form.Label>Nueva Contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Ingrese la contraseña"
                                    name="pass"
                                    value={contrasena.pass}
                                    onChange={handleChange}
                                />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formConfirmarContrasena">
                                <Form.Label>Confirmar Contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Ingrese la descripción"
                                    name="confirmpass"
                                    value={contrasena.confirmpass}
                                    onChange={handleChange}
                                    isInvalid={tocado.confirmpass && contrasena.pass !== contrasena.confirmpass}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Las contraseñas no coinciden.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <div className="align-self-end mt-auto p-2">
                                <Button style={{marginRight:'1rem'}} variant="primary" type="submit" disabled={!contrasena.pass || !contrasena.confirmpass || (contrasena.pass !== contrasena.confirmpass) }>
                                    Guardar Cambio
                                </Button>
                                <Link to="/admin/categorias/nuevo">
                                    <Button variant="danger">
                                        Regresar
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CambiarContrasenaPage;
