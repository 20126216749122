// UsuariosPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UsuariosTable from '../Components/Tables/UsuariosTable';

const UsuariosPage = () => {
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    axios
      .get('https://api.meycisstore.com/users', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener datos de usuarios:', error);
      });
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Usuarios</Card.Title>
        <Link to="/admin/usuarios/nuevo">
          <Button className="bg-meycs" variant="primary">
            Nuevo
          </Button>
        </Link>
      </Card.Header>
      <Card.Body>
        <UsuariosTable usuarios={usuarios} />
      </Card.Body>
    </Card>
  );
};

export default UsuariosPage;
