import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

const PreciosEnvioForm = () => {
    const [envioGratuito, setEnvioGratuito] = useState({ valor: "", clave: "envio-gratis" });
    const [minimoCompra, setMinimoCompra] = useState({ valor: "", clave: "minimo-compra" });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `${localStorage.getItem('token')}`,
    };

    useEffect(() => {
        const fetchConfiguraciones = async () => {
            try {
                const [envioGratisResponse, minimoCompraResponse] = await Promise.all([
                    axios.get("https://api.meycisstore.com/configuracion/envio-gratis", { headers }),
                    axios.get("https://api.meycisstore.com/configuracion/minimo-compra", { headers }),
                ]);

                setEnvioGratuito({ valor: envioGratisResponse.data.valor, clave: "envio-gratis" });
                setMinimoCompra({ valor: minimoCompraResponse.data.valor, clave: "minimo-compra" });
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: `Error al cargar las configuraciones: ${error}`,
                    icon: 'error'
                });
            }
        };

        fetchConfiguraciones();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!minimoCompra.valor) {
            Swal.fire({
                title: 'Validación',
                text: 'Es necesario ingresar un mínimo de compra',
                icon: 'error'
            });
            return;
        }
        if (!envioGratuito.valor) {
            Swal.fire({
                title: 'Validación',
                text: 'Es necesario ingresar un monto de envio gratuito',
                icon: 'error'
            });
            return;
        }

        const envioGratisRequest = axios.post("https://api.meycisstore.com/configuracion", envioGratuito, { headers });
        const minimoCompraRequest = axios.post("https://api.meycisstore.com/configuracion", minimoCompra, { headers });

        Promise.all([envioGratisRequest, minimoCompraRequest])
            .then(([responseEnvioGratis, responseMinimoCompra]) => {
                Swal.fire({
                    title: 'Confirmación',
                    text: 'Datos de envío actualizados correctamente',
                    icon: 'success',
                });
            })
            .catch(error => {
                const errorMessage = `Error al actualizar los datos de envío: ${error}`;
                Swal.fire({
                    title: 'Error',
                    text: errorMessage,
                    icon: 'error'
                });
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'envioGratuito') {
            setEnvioGratuito({ ...envioGratuito, valor: value });
        } else if (name === 'minimoCompra') {
            setMinimoCompra({ ...minimoCompra, valor: value });
        }
    };

    return (
        <Form className='mt-3' onSubmit={handleSubmit}>
            <p>En esta sección puedes configurar las opciones de envío gratuito y el mínimo de compra para obtenerlo.</p>
            <div className="col-md-4">
                <Form.Group className="mb-3" controlId="formEnvioGratuito">
                    <Form.Label>Envio Gratuito</Form.Label>
                    <Form.Control
                        type="number"
                        name="envioGratuito"
                        value={envioGratuito.valor}
                        onChange={handleChange}
                        placeholder="Ingrese el costo del envio gratuito"
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMinimoCompra">
                    <Form.Label>Mínimo de compra</Form.Label>
                    <Form.Control
                        type="number"
                        name="minimoCompra"
                        value={minimoCompra.valor}
                        onChange={handleChange}
                        placeholder="Ingrese el mínimo de compra"
                    />
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" type="submit">
                        Actualizar Datos de Envío
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default PreciosEnvioForm;
