import React, { useMemo } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';

const CategoriasTable = ({ categorias }) => {
    const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_categoria',
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Descripción',
        accessor: 'descripcion',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones', // Nuevo accessor ficticio para la columna de acciones
        // Nueva columna sin nombre para acciones
        Cell: ({ row }) => {

          const handleEdit = (categoria) => {
            navigate(`/admin/categorias/editar/${categoria.id_categoria}`)
          };

          const handleDelete = (categoriaId) => {
            // Lógica para eliminar la categoría
            console.log(`Eliminar categoría con ID: ${categoriaId}`);
          };

          return (
          <div className="d-flex justify-content-around">
            <Button variant="primary" size="sm"  onClick={() => handleEdit(row.original)}>
                <FaEdit />
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDelete(row.original.id_user)}
              >
                <FaTrash />
              </Button>
          </div>
        );
        }
      },
    ],
    [navigate]
  );

  return <DataTable columns={columns} data={categorias} />;
};

export default CategoriasTable;
