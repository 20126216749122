import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';

const NumberCard = ({ title, targetValue }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    // Configura una animación sencilla para aumentar el valor gradualmente
    const animationDuration = 1000; // Duración de la animación en milisegundos
    const framesPerSecond = 60;
    const totalFrames = (animationDuration / 1000) * framesPerSecond;

    const step = (targetValue - currentValue) / totalFrames;

    const intervalId = setInterval(() => {
      setCurrentValue((prevValue) => prevValue + step);
    }, 1000 / framesPerSecond);

    // Limpia el intervalo cuando el componente se desmonta o cuando alcanza el valor objetivo
    return () => {
      clearInterval(intervalId);
    };
  }, [currentValue, targetValue]);

  return (
    <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title>{title}</Card.Title>
        </Card.Header>
      <Card.Body>
        <Card.Text className='text-end'>
          <span className='h1'>{Math.round(currentValue)}</span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default NumberCard;
