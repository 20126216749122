import React, { useMemo } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';

const ClientesTable = ({ clientes }) => {
  const navigate = useNavigate();
  
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_cliente',
      },
      {
        Header: 'Nombre',
        accessor: ({ nombre, apellidos }) => `${nombre} ${apellidos}`,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Total Compras',
        accessor: 'total_compras',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones', // Nuevo accessor ficticio para la columna de acciones
        // Nueva columna sin nombre para acciones
        Cell: ({ row }) => {
          const handleEditCliente = (cliente) => {
            navigate(`/admin/clientes/editar/${cliente.id_cliente}`);
          };

          return(
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm"  onClick={() => handleEditCliente(row.original)}>
                  <FaEdit />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteCliente(row.original.id_user)}
                >
                  <FaTrash />
                </Button>
            </div>
          )
        },
      },
    ],
    [navigate]
  );

  const handleDeleteCliente = (clienteId) => {
    // Lógica para eliminar el cliente
    console.log(`Eliminar cliente con ID: ${clienteId}`);
  };

  return <DataTable columns={columns} data={clientes} />;
};

export default ClientesTable;
