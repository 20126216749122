import React, { useEffect, useState } from 'react';
import { Button, Card,Col,Form, Row  } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import CalculadoraMonedas from '../../Components/Shared/CalculadoraMonedas';

const AbrirCajaPage = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const [modalShow, setModalShow] = useState(false);
    const [cajaActiva, setCajaActiva] = useState({
        id_user:0,
        id_sucursal:0,
        nombre:"",
        total_fondo:0,
        des_fondo:{}
    });
    const [desloce,setDesloce] = useState("");

    const [tocado, setTocado] = useState({
        pass: false,
        confirmpass: false,
    });

    useEffect(()=>{
        const cargarDatosUsuario =() =>{
            setCajaActiva(prevState => ({
                ...prevState,
                id_user: user.id,
                id_sucursal: user.id_sucursal,
                nombre:`${user.nombre} ${user.apellido}`
            }));
        }

        cargarDatosUsuario();
    },[user.apellido, user.id, user.id_sucursal, user.id_user, user.nombre])

    useEffect(()=>{
        const token = localStorage.getItem('token');
        axios.get('https://api.meycisstore.com/caja/verificar',{
            headers:{
                "Content-Type":'application/json',
                Authorization:token
            }
        }).then((response)=>{
            if (response.status === 200)
            {
                navigate('/pos/venta');
            }
        }).catch((error)=>{
            console.log(error);
            console.log(error.response.status);
            if(error.response.status === 510)
            {
                Swal.fire({
                    title:'Advertencia',
                    text:`${error.response.data.message}: No podra realizar ventas ¿Desea continuar?`,
                    icon:'warning',
                    confirmButtonText:'Si. continuar',
                    showCancelButton:true,
                    cancelButtonText:'Salir',
                    allowEscapeKey:false,
                    allowOutsideClick:false
                }).then( (result)=>{
                    console.log(result);
                    if( result.isConfirmed )
                    {
                        navigate('/pos/venta');
                    }

                    if (result.dismiss === 'cancel') {
                        navigate('/login');
                    }
                });
            }else
            {
                Swal.fire({
                    icon:'warning',
                    title:'Advertencia',
                    text: error.response.data.message
                });
            }
        });
    },[navigate])

    const handleChange = (e) => {
    // Actualiza el estado de la categoría al escribir en los campos del formulario
    const { name } = e.target;

    setTocado({ ...tocado, [name]: true });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({
            title:'Advertencia',
            text:'No se encontro un token de autorizaciòn',
            icon:'error'
            });
            return;
        }

        const datosContrasena = {
            monto:cajaActiva.total_fondo,
            desloce:desloce
        };

        axios.post('https://api.meycisstore.com/abrirCaja', datosContrasena, {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
            },
        })
        .then(response => {
            if(response.data.advertencia.mensaje)
            {
                Swal.fire({
                    title:'Advertencia',
                    text:response.data.advertencia.mensaje,
                    icon:'warning'
                }).then(()=>{
                    navigate('/pos/venta');
                });
            }else
            {
                Swal.fire({
                    title:'Confirmacion',
                    text:'Se abrio la caja correctamente',
                    icon:'success'
                }).then(()=>{
                    navigate('/pos/venta');
                });
            }
        })
        .catch(error => {
            Swal.fire({
                title:'Error',
                text:`${error.response.data.message}: ¿Desea continuar?`,
                icon:'error',
                confirmButtonText:'Si. continuar'
            }).then( (result)=>{
                if( result.isConfirmed )
                {
                    navigate('/pos/venta');
                }
            });
        })
    };

    const abrirCalculador = () => {
        setModalShow(true);
    };

    const cerrarCalculador = () => {
        setModalShow(false);
    };

    const handleCalcular = (total,desloce) => {
        setCajaActiva(prevState => ({
            ...prevState,
            total_fondo: total
        }));
        setDesloce(desloce);
    };

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Abrir Caja</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className='h-100 d-flex justify-content-center'>
            <Col sm={12} md={4} >
                <Card className='h-100'>
                    <Card.Body>
                        <Form className='h-100 d-flex flex-column' onSubmit={handleSubmit}>
                            <div>
                                <Form.Group className="mb-3" controlId="formContrasena">
                                <Form.Label>Usuario</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese el usuario"
                                    name="nombre"
                                    value={cajaActiva.nombre}
                                    disabled={true}
                                    onChange={handleChange}
                                />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formConfirmarContrasena">
                                <Form.Label>Fondo</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Ingrese la descripción"
                                    name="total_fondo"
                                    value={cajaActiva.total_fondo}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                                </Form.Group>
                            </div>

                            <div className="align-self-end mt-auto p-2">
                                <Button style={{marginRight:'1rem'}} variant="primary" type="submit" disabled={ cajaActiva.total_fondo === 0 } >
                                    Abrir Caja
                                </Button>
                                <Button onClick={abrirCalculador} variant="danger">
                                    Contar fondo
                                </Button>
                                <CalculadoraMonedas
                                    show={modalShow}
                                    onClose={cerrarCalculador}
                                    onCalcular={handleCalcular}
                                />
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AbrirCajaPage;
