import React, { useState, useEffect } from 'react';

const Reloj = () => {
  const [hora, setHora] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHora(new Date());
    }, 1000);

    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []); // La dependencia vacía asegura que useEffect se ejecute solo una vez al montar el componente

  const formatoHora = (numero) => (numero < 10 ? `0${numero}` : numero);
  const formato12Horas = (hora24) => (hora24 % 12 || 12);

  const horas = formatoHora(formato12Horas(hora.getHours()));
  const minutos = formatoHora(hora.getMinutes());
  const segundos = formatoHora(hora.getSeconds());
  const amPm = hora.getHours() >= 12 ? 'PM' : 'AM';

  return (
    <div>
      <p className='h4'>{`${horas}:${minutos}:${segundos} ${amPm}`}</p>
    </div>
  );
};

export default Reloj;
