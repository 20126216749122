import React, { useEffect, useState } from 'react';
import { Card,Form  } from 'react-bootstrap';
import ChecadorTable from '../Components/Tables/ChecadorTable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const ChecadorPage = () => {
  const [registrosChecador, setRegistrosChecador] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    // Función para cargar los registros del checador
    const fetchRegistrosChecador = async () => {
      try {
        const response = await axios.get(`https://api.meycisstore.com/users/accesos/${ selectedDate.toISOString().split('T')[0]}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('token')}`,
          },
        });
        setRegistrosChecador(response.data);
      } catch (error) {
        console.error('Error al obtener datos del checador:', error);
      }
    };

    // Llama a la función al cargar la página y cada vez que cambie la fecha
    fetchRegistrosChecador();
  }, [selectedDate]);

  const handleDateChange = (date) => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    setSelectedDate(adjustedDate);
  };

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Checador</Card.Title>
        <div className="d-flex">
            <Form.Group className="mr-2">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </Form.Group>
        </div>
      </Card.Header>
      <Card.Body>
        <ChecadorTable registrosChecador={registrosChecador} />
      </Card.Body>
    </Card>
  );
};

export default ChecadorPage;
