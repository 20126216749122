import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CortesDetallePage = () => {
  const [cortes, setCortes] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    // Simulación de carga de datos de cortes desde la API
    axios.get(`https://api.meycisstore.com/cortes/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setCortes(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de cortes:', error);
      });
  }, [id]);

  const formatearFecha = (fechaString) => {
    const fecha = new Date(fechaString);
  
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const ampm = horas >= 12 ? 'PM' : 'AM';
    const hora12 = horas % 12 || 12;
  
    return `${dia}/${mes}/${anio} ${hora12}:${minutos}:${segundos} ${ampm}`;
  }

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Cortes de Caja- Detalle</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
          <h3 className="h2">
            Detalles del Corte
          </h3>
          <Table striped bordered hover responsive className='mb-5'>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Empleado</th>
                <th>Fondo Inicial</th>
                <th>Total Retiros</th>
                <th>Diferencia Efectivo</th>
                <th>Diferencia Tarjetas</th>
              </tr>
            </thead>
            <tbody>
              {
                cortes.User ? 
                <tr>
                  <td> { formatearFecha( cortes.createdAt)} </td>
                  <td> {cortes.User.nombre} {cortes.User.apellidos} </td>
                  <td>$ {cortes.monto_inicial} </td>
                  <td>$ {cortes.total_retiros} </td>
                  <td>$ {cortes.diferencia_efectivo ? cortes.diferencia_efectivo : '0.00'} </td>
                  <td>$ {cortes.diferencia_tarjeta ? cortes.diferencia_tarjeta : '0.00'} </td>
                </tr>
              :
              <tr><td colSpan={6} >SIN INFORMACION PARA MOSTRAR</td></tr>
              }
              
            </tbody>
          </Table>
          <h3 className="h2">
            Detalles Ventas
          </h3>
          <Table striped bordered hover responsive className='mb-5'>
            <thead>
              <tr>
                <th>No. Ventas</th>
                <th>Total Ventas</th>
                <th>Efectivo</th>
                <th>Tarjetas</th>
              </tr>
            </thead>
            <tbody>
              {
                cortes ? 
                <tr>
                  <td> {cortes.num_ventas}</td>
                  <td>$ {(parseInt(cortes.total_ventas_efectivo) + parseInt(cortes.total_ventas_tarjeta)).toFixed(2) } </td>
                  <td>$ {cortes.total_ventas_efectivo} </td>
                  <td>$ {cortes.total_ventas_tarjeta} </td>
                </tr>
              :
              <tr><td colSpan={6} >SIN INFORMACION PARA MOSTRAR</td></tr>
              }
              
            </tbody>
          </Table>
          <Table striped bordered hover responsive className='mb-5'>
            <thead>
              <tr>
                <th>Fecha y Hora</th>
                <th>Cliente </th>
                <th>Total Compra</th>
                <th>Descuento</th>
                <th>Utilidad</th>
              </tr>
            </thead>
            <tbody>
              {
                cortes.Ventas ? 
                cortes.Ventas.map((venta)=>{
                  return <tr key={venta.id_venta}>
                    <td> { formatearFecha(venta.createdAt)}</td>
                    <td> { venta.Cliente.nombre } {venta.Cliente.apellidos} </td>
                    <td>$ { venta.monto_total } </td>
                    <td>$ { venta.total_descuento} </td>
                    <td>$ { venta.total_utilidad} </td>
                  </tr>
                })
              :
              <tr><td colSpan={6} >SIN INFORMACION PARA MOSTRAR</td></tr>
              }
              
            </tbody>
          </Table>
          <h3 className="h2">
            Retiros
          </h3>
          <Table striped bordered hover responsive className='mb-5'>
            <thead>
              <tr>
                <th>Fecha y Hora</th>
                <th>Total</th>
                <th>Motivo</th>
              </tr>
            </thead>
            <tbody>
              {
                cortes.Retiros ? 
                cortes.Retiros.map((retiro)=>{
                  return <tr key={retiro.id_retiro}>
                    <td> { formatearFecha(retiro.createdAt)}</td>
                    <td>$ { retiro.monto_retiro } </td>
                    <td> { retiro.motivo} </td>
                  </tr>
                })
              :
              <tr><td colSpan={6} >SIN INFORMACION PARA MOSTRAR</td></tr>
              }
              
            </tbody>
          </Table>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default CortesDetallePage;
