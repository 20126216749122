import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';

const TallasTable = ({ tallas }) => {
  const navigate = useNavigate();
  const [tallasList, setTallas] = useState(tallas);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_talla',
        width: 25,
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {
          const handleEditTalla = () => {
            navigate(`/admin/tallas/editar/${row.original.id_talla}`);
          };

          const handleDeleteTalla = (tallaId) => {
            Swal.fire({
              title: '¿Estás seguro?',
              text: 'Esta acción eliminará la talla. No podrás deshacer este cambio.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d3a000',
              confirmButtonText: 'Sí, eliminar',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                // Si el usuario confirma, realizar la eliminación
                axios
                  .delete(`https://api.meycisstore.com/tallas/${tallaId}`, {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `${localStorage.getItem('token')}`,
                    },
                  })
                  .then(() => {
                    setTallas((prevTallas) =>
                      prevTallas.filter((talla) => talla.id_talla !== tallaId)
                    );
                    Swal.fire({
                      title: 'Confirmación',
                      text: 'La talla fue eliminada correctamente.',
                      icon: 'success',
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      text: 'No se logró eliminar la talla. Por favor, inténtelo nuevamente.',
                      icon: 'error',
                    });
                  });
              }
            });
          };

          return (
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm" onClick={handleEditTalla}>
                <FaEdit />
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDeleteTalla(row.original.id_talla)}
              >
                <FaTrash />
              </Button>
            </div>
          );
        },
        width: 50,
      },
    ],
    [navigate]
  );

  useEffect(() => {
    setTallas(tallas);
  }, [tallas]);

  return <DataTable columns={columns} data={tallasList} />;
};

export default TallasTable;
