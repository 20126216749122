import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UsuariosForm = ({ match }) => {
    const navigate = useNavigate();
    const [sucursales, setSucursales] = useState([]);

    const [usuario, setUsuario] = useState({
        id: "",
        nombre: "",
        apellidos: "",
        contrasena: "",
        confirmarContrasena: "",
        sucursal: "",
        rol: "",
        email: "",
    });

  const [tocado, setTocado] = useState({
    nombre: false,
    apellidos: false,
    email: false,
    contrasena: false,
    confirmarContrasena: false,
    sucursal: false,
    rol: false
  });

    function goBack() {
        navigate(-1);
    }

    useEffect(()=>{
      const fetchSucursales=()=>{
        const token = localStorage.getItem('token');
        axios.get('https://api.meycisstore.com/sucursales',{
          headers:{
            "Content-Type":'application/json',
            Authorization:token
          }
        }).then((response)=>{
          setSucursales(response.data);
        }).catch((error)=>{
          Swal.fire({
            title:'Advertencia',
            text:`Ocurrio un error al obtener las sucursales:${error.response.data.message}`,
            icon:'warning'
          })
        })
      }
      fetchSucursales();
    },[])

  const handleChange = (e) => {
    // Actualiza el estado del usuario al escribir en los campos del formulario
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
    setTocado({ ...tocado, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (usuario.contrasena !== usuario.confirmarContrasena) {
      Swal.fire({
        title:'Validación',
        text:'Las contraseñas no coinciden.',
        'icon':'warning'
      });
      return;
    }
  
    const token = localStorage.getItem('token');
    if (!token) {
      Swal.fire({
        title:'Validación',
        text:'No se encontró un token de autorización',
        icon:'warning'
      });
      return;
    }

    const datosUsuario = {
        nombre: usuario.nombre,
        apellidos: usuario.apellidos,
        pass: usuario.contrasena,
        id_sucursal: usuario.sucursal,
        id_rol: usuario.rol,
        email: usuario.email,
    };
  
    axios.post('https://api.meycisstore.com/users', datosUsuario, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        setUsuario(response.data);
        Swal.fire({
          title:'Confirmacion',
          text:`Se creó el usuario correctamente`,
          icon:'success'
        }).then(()=>{
          navigate('/admin/usuarios');
        });
      })
      .catch(error => {
        Swal.fire({
          title:'Confirmacion',
          text:`Error al crear el usuario:${error}`,
          icon:'error'
        });
      })
  };
  


  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Nuevo usuario</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
        <Form onSubmit={handleSubmit}  >
            <Form.Group className="mb-3" controlId="formNombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                name="nombre"
                value={usuario.nombre}
                onChange={handleChange}
                
            />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formApellidos">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control
                type="text"
                placeholder="Ingrese los apellidos"
                name="apellidos"
                value={usuario.apellidos}
                onChange={handleChange}
            />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMail">
            <Form.Label>Email</Form.Label>
            <Form.Control
                type="mail"
                placeholder="Ingrese un correo electronico"
                name="email"
                value={usuario.email}
                onChange={handleChange}
            />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContraseña">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
                type="password"
                placeholder="Ingrese la contraseña"
                name="contrasena"
                value={usuario.contrasena}
                onChange={handleChange}
                
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formConfirmarContraseña">
            <Form.Label>Confirmar Contraseña</Form.Label>
            <Form.Control
                type="password"
                placeholder="Confirme la contraseña"
                name="confirmarContrasena"
                value={usuario.confirmarContrasena}
                
                onChange={handleChange}
                isInvalid={tocado.confirmarContrasena && usuario.contrasena !== usuario.confirmarContrasena}
            />
            <Form.Control.Feedback type="invalid">
                Las contraseñas no coinciden.
            </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSucursal">
            <Form.Label>Sucursal</Form.Label>
            <Form.Control
                as="select"
                name="sucursal"
                
                value={usuario.sucursal}
                onChange={handleChange}
            >
                <option>Seleccionar sucursal...</option>
                {sucursales.map((sucursal) => (
                    <option key={sucursal.id_sucursal} value={sucursal.id_sucursal}>
                    {sucursal.nombre}
                    </option>
                  ))}
            </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRol">
            <Form.Label>Rol</Form.Label>
            <Form.Control
                as="select"
                name="rol"
                
                value={usuario.rol}
                onChange={handleChange}
            >
                <option>Seleccionar rol...</option>
                <option value="1">Administrador</option>
                <option value="2">Supervisor</option>
                <option value="3">Vendedor</option>
            </Form.Control>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" disabled={!usuario.nombre || !usuario.apellidos || !usuario.email || !usuario.contrasena || !usuario.confirmarContrasena || !usuario.sucursal || !usuario.rol}>
                Enviar
              </Button>
              <Button variant="danger" className="ms-2" onClick={goBack} >
                Regresar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default UsuariosForm;
