import React from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { Table, Container, Form, Pagination } from 'react-bootstrap';

const DataTable = ({ columns, data, searchParam, showPagination }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    state,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;

  const handleSearchChange = (e) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Container fluid className="mt-5">
      {searchParam ? null :
        (<div className='d-flex'>
          <Form className="mb-3 col-12 col-md-3 col-offset-md-9">
            <Form.Group controlId="search">
              <Form.Control
                type="text"
                value={globalFilter || ''}
                onChange={handleSearchChange}
                placeholder="Buscar..."
              />
            </Form.Group>
          </Form>
        </div>
        )
      }
      <Table {...getTableProps()} striped bordered hover responsive>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={{ width: column.width || 'auto' }}
            >
              {column.render('Header')}
              <span>{column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}</span>
            </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {page.length > 0 ? (
        page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })
      ) : (
        <tr>
          <td className='text-center' colSpan={columns.length}>Sin información para mostrar</td>
        </tr>
      )}
      </tbody>
    </Table>
    {showPagination ? null: (
      <Pagination>
        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
      </Pagination>
    )}
    </Container>
  );
};

export default DataTable;
