import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const CalculadoraMonedas = ({ show, onClose, onCalcular }) => {
  const [valores, setValores] = useState({
    mil: 0,
    quinientos: 0,
    doscientos: 0,
    cien: 0,
    cincuenta: 0,
    veinte: 0,
    diez: 0,
    cinco: 0,
    uno: 0,
    cincuentaCentavos: 0,
  });

  const valoresNumericos = {
    mil: 1000,
    quinientos: 500,
    doscientos: 200,
    cien: 100,
    cincuenta: 50,
    veinte: 20,
    diez: 10,
    cinco: 5,
    uno: 1,
    cincuentaCentavos: 0.50,
  };

  const [total, setTotal] = useState(0);

  const handleInputChange = (label, value) => {
    const updatedValores = {
      ...valores,
      [label]: value,
    };

    setValores(updatedValores);

    // Calcular el total
    const totalCalculado = Object.entries(updatedValores).reduce(
      (accumulator, [label, value]) => accumulator + parseFloat(valoresNumericos[label]) * value,
      0
    );

    setTotal(totalCalculado.toFixed(2));
  };

  const handleClose = () => {
    // Llama a la función onCalcular con el total antes de cerrar el modal
    onCalcular(total,JSON.stringify(valores));
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Contador de Monedas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            {
                Object.entries(valores).map(([label, valorInicial]) => (
                    <>
                        <Col sm={6} className='mb-3 text-center'>
                            <Form.Label key={`label-${label}`}>{`$${valoresNumericos[label]}`}</Form.Label>
                        </Col>
                        <Col sm={6} className='mb-3'>
                            <Form.Group key={label} controlId={label}>
                                <Form.Control
                                    type="number"
                                    value={valores[label]}
                                    onChange={(e) => handleInputChange(label, e.target.value)}
                                />
                            </Form.Group>
                        </Col> 
                    </>
                ))
            }
          </Row>
          <Row>
            <Form.Group as={Col} controlId="total" className="mb-3">
              <Form.Label>Total</Form.Label>
              <Form.Control type="text" value={`$${total}`} disabled />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Guardar y Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalculadoraMonedas;
