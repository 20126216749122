import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import RetirosTable from '../Components/Tables/RetirosTable';  // Asegúrate de tener el componente RetirosTable creado
import axios from 'axios';

const RetirosPage = () => {
  const [retiros, setRetiros] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de retiros desde la API
    axios.get('https://api.meycisstore.com/retiros', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setRetiros(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de retiros:', error);
      });
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Retiros</Card.Title>
      </Card.Header>
      <Card.Body>
        <RetirosTable retiros={retiros} />
      </Card.Body>
    </Card>
  );
};

export default RetirosPage;
