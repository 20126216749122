import React from 'react';
import { Chart,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend } from "chart.js";
import { Bar } from 'react-chartjs-2';

Chart.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);
const options = {
  responsive: true,
};

const BarChart = ({ dataChar }) => {
  return (
    <div>
      <Bar data={dataChar} options={options} />
    </div>
  );
};

export default BarChart;
