import React, { useEffect, useMemo, useRef, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaEdit, FaPrint } from 'react-icons/fa'; 
import withReactContent from 'sweetalert2-react-content';
import Barcode from 'react-barcode';
import {toPng} from 'html-to-image';
import axios from 'axios';

const ProductosVendedorTable = ({ productos }) => {
  const navigate = useNavigate();
  const refEtiqueta = useRef(null);
  const [tallas, setTallas] = useState([]);
  const [datosEtiqueta,setDatosEtiqueta] = useState({});

  useEffect(()=>{
    const handleFetchTallas = async ()=>{
      try {
        const response = await axios.get('https://api.meycisstore.com/tallas',{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${localStorage.getItem('token')}`,
          }
        });
        setTallas(response.data);
      } catch (error) {
        console.error('Error al obtener las tallas:', error);
      }
    }

    handleFetchTallas();
  },[])

  const columns = useMemo(
    () => [
      {
        Header: 'ID Producto',
        accessor: 'id_producto',
      },
      {
        Header: 'Imagen',
        accessor: 'imagen',
        Cell: ({ row }) => {
          return row.original.imagen ? (
            <img src={row.original.imagen} alt="Imagen" style={{ width: '50px', height: '50px' }} />
          ) : (
            <img src="https://9931890f7c.cbaul-cdnwnd.com/d04f2c1531f2b3361e7500a0d9ec6946/200000077-87966888e4/Imagen-no-disponible.png?ph=9931890f7c" alt="Imagen no disponible" style={{ width: '50px', height: '50px' }} />
          );
        },
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Precio Venta',
        accessor: 'precio_venta',
        Cell:({row}) =>{
          return row.original.precio_venta.toFixed(2);
        },
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ row }) => {
          const statusLabel = row.original.status === 1 ? 'Activo' : 'Agotado';
          return statusLabel;
        },
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {

          const handleEditProducto = (producto) => {
            navigate(`/pos/productos/editar/${producto.id_producto}`);
          };

          const handlePrintProducto = async (productoId) => {
            const detalleProducto = await axios.get(`https://api.meycisstore.com/productos/${productoId}`, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}`,
                  },
              });
        
              if (detalleProducto.status !== 200) {
                Swal.fire({
                  title:'Advertencia',
                  icon:'warning',
                  text:'No es posible imprimir etiquetas en este momento'
                });
                return;
              }

              const tallasProducto = tallas.filter((talla)=>  detalleProducto.data.id_talla.includes(talla.id_talla) );
              withReactContent(Swal).fire({
                title: 'Imprimir Etiqueta',
                html: (
                  <Form id="miFormulario">
                    <Form.Group controlId="talla" className='mb-3'>
                      <Form.Label>Talla</Form.Label>
                      <Form.Select>
                        {
                          tallasProducto.map((talla)=> <option key={talla.id_talla} value={talla.id_talla} >{talla.nombre} </option> )
                        }
                      </Form.Select>
                    </Form.Group>
                  </Form>
                ),
                showCancelButton: true,
                confirmButtonText: 'Imprimir',
                cancelButtonText: 'Cancelar',
                preConfirm: async () => {
                  const opcion = document.getElementById('talla').value;
                  const sku = productoId.toString().padStart(6,'0') + opcion.toString().padStart(3,'0');
                  setDatosEtiqueta({
                    sku:sku,
                    precio:detalleProducto.data.precio_venta.toFixed(2),
                    texto:`${detalleProducto.data.nombre}`,
                  });
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  toPng(refEtiqueta.current)
                  .then((dataUrl) => {
                    // Crear un enlace para descargar la imagen
                    var link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'etiqueta.png';
                    link.click();
                  })
                  .catch((error) => {
                    console.error('Error al generar la imagen:', error);
                  }).finally(()=>{
                    setDatosEtiqueta({});
                  });
                }
              });
          };

          return(
            <div className='d-flex justify-content-around'>
              <Button variant="warning" size="sm" onClick={() => handlePrintProducto(row.original.id_producto)}>
                <FaPrint />
              </Button>
              <Button variant="primary" size="sm" onClick={() => handleEditProducto(row.original)}>
                <FaEdit />
              </Button>
            </div>
          )
        },
        width:25
      },
    ],
    [navigate,tallas]
  );

  return (
    <>
      <DataTable columns={columns} data={productos} />
      <div style={{paddingTop:'440px'}} className='row' ref={refEtiqueta}>
        {
          datosEtiqueta.texto ? 
          <div style={{marginLeft:'550px'}} className='col-md-6 pt-5'>
            <p className='text-center pt-5' style={{fontSize:'30px'}}>{datosEtiqueta.texto} </p>
            <p style={{fontSize:'40px',marginBottom:'75px'}} className='text-center'>$ {datosEtiqueta.precio} </p>
            <div className='d-flex justify-content-center' >
              <Barcode value={datosEtiqueta.sku} width={3} height={35} />
            </div>
          </div>: null
        }
      </div>
    </>)
};

export default ProductosVendedorTable;
