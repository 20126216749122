
import React from 'react';
import { css } from '@emotion/react';
import { SyncLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const LoadingBlock = () => {
    return (
        <div className="loading-block">
        <SyncLoader css={override} size={10} color={'#d3a000'} loading={true} />
        </div>
    );
};

export default LoadingBlock;