import React, { useEffect } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer
} from "react-router-dom";
import LoginPage from './Components/Login/Login';
import { useAuth } from './Hooks/useAuth'; // Ajusta la ruta según tu estructura de archivos
import { ProtectedLayout } from "./Components/Shared/ProtectedLayout";
import { AuthLayout } from "./Components/Shared/AuthLayout.jsx";
import { DashboardPage } from "./Pages/Dashboard.jsx";
import UsuariosPage from "./Pages/Usuarios.jsx";
import UsuariosForm from "./Pages/UsuariosForm.jsx";
import EditarUsuarioForm from "./Pages/UsuariosFormEdit.jsx";
import SucursalesPage from "./Pages/Sucursales.jsx";
import SucursalesForm from "./Pages/SucursalesForm.jsx";
import SucursalesFormEdit from "./Pages/SucursalesFormEdit.jsx";
import ProductosPage from "./Pages/Productos.jsx";
import ProductosForm from "./Pages/ProductosForm.jsx";
import CategoriasPage from "./Pages/CategoriasPage.jsx";
import CategoriasForm from "./Pages/CategoriasForm.jsx";
import CategoriasFormEdit from "./Pages/CategoriasFormEdit.jsx";
import ClientesPage from "./Pages/ClientesPage.jsx";
import ClientesForm from "./Pages/ClientesForm.jsx";
import ClienteFormEdit from "./Pages/ClienteFormEdit.jsx";
import ChecadorPage from "./Pages/ChecadorPage.jsx";
import TallasPage from "./Pages/TallasPage.jsx";
import TallasForm from "./Pages/TallasForm.jsx";
import InventariosPage from "./Pages/InventariosPage.jsx";
import InventariosForm from "./Pages/InventariosForm.jsx";
import InventarioAuditPage from "./Pages/InventarioAuditPage.jsx";
import NotFoundPage from './Pages/NotFoundPage';
import VentasPage from "./Pages/VentasPage.jsx";
import VentasSucursalPage from "./Pages/VentasSucursalPage.jsx";
import WebsitePage from "./Pages/WebsitePage.jsx";

import RetirosPage from "./Pages/RetirosPage.jsx";
/*
import PromocionesPage from "./Pages/PromocionesPage.jsx";
*/
import CortesPage from "./Pages/CortesPage.jsx";
import CortesDetallePage from "./Pages/CortesDetallePage.jsx";
import CambiarContrasenaPage from "./Pages/CambiarContrasenaPage.jsx";


import {PosLayout} from "./Components/Shared/PosLayout.jsx";
import AbrirCajaPage from "./Pages/PosPages/AbrirCajaPage.jsx";
import VentaPage from "./Pages/PosPages/VentaPage.jsx";
import ProductosVendedor from "./Pages/PosPages/ProductosVendedor.jsx";
import ProductosVendedorForm from './Pages/PosPages/ProductosVendedorForm.jsx';
import InventariosVendedorPage from './Pages/PosPages/InventarioVendedor.jsx';

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 3000)
  );

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<LoginPage />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route component={NotFoundPage} />
      </Route>

      <Route path="/pos" element={<PosLayout />}>
        <Route path="inicio" element={<AbrirCajaPage />} />
        <Route path="venta" element={<VentaPage />} />
        <Route path="productos" element={<ProductosVendedor />} />
        <Route path="productos/nuevo" element={<ProductosVendedorForm />} />
        <Route path="productos/editar/:id" element={<ProductosVendedorForm />} />
        <Route path="inventario" element={<InventariosVendedorPage />} />
        <Route path="inventario/editar/:id" element={<InventariosForm />} />
        <Route component={NotFoundPage} />
      </Route>

      <Route path="/admin" element={<ProtectedLayout />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="usuarios" element={<UsuariosPage />} />
        <Route path="usuarios/nuevo" element={<UsuariosForm />} />
        <Route path="usuarios/editar/:id" element={<EditarUsuarioForm />} />
        <Route path="usuarios/checador" element={<ChecadorPage />} />
        <Route path="sucursales" element={<SucursalesPage />} />
        <Route path="sucursales/nueva" element={<SucursalesForm />} />
        <Route path="sucursales/editar/:id?" element={<SucursalesFormEdit />} />
        <Route path="productos" element={<ProductosPage />} />
        <Route path="productos/nuevo" element={<ProductosForm />} />
        <Route path="productos/editar/:id" element={<ProductosForm />} />
        <Route path="productos-pendientes" element={<ProductosPage />} />
        <Route path="categorias" element={<CategoriasPage />} />
        <Route path="categorias/nuevo" element={<CategoriasForm />} />
        <Route path="categorias/editar/:id" element={<CategoriasFormEdit />} />
        <Route path="clientes" element={<ClientesPage />} />
        <Route path="clientes/nuevo" element={<ClientesForm />} />
        <Route path="clientes/editar/:id" element={<ClienteFormEdit />} />
        <Route path="tallas" element={<TallasPage />} />
        <Route path="tallas/nuevo" element={<TallasForm />} />
        <Route path="tallas/editar/:id" element={<TallasForm />} />
        <Route path="inventario" element={<InventariosPage />} />
        <Route path="inventario/editar/:id" element={<InventariosForm />} />
        <Route path="inventario/audit" element={<InventarioAuditPage />} />
        <Route path="ventas" element={<VentasPage />} />
        <Route path="ventas/sucursal/:id" element={<VentasSucursalPage />} />
        <Route path="retiros" element={<RetirosPage />} />
        <Route path="cortes-caja" element={<CortesPage />} />
        <Route path="cortes/:id" element={<CortesDetallePage />} />
        <Route path="cambiar-contrasena" element={<CambiarContrasenaPage />} />
        <Route path="paginaweb" element={<WebsitePage />} />
        
        <Route path="*"component={NotFoundPage} />
      </Route>
    </Route>
  )
);

const App = () => {
  const { isTokenExpired } = useAuth();

  useEffect(() => {
    // Verificar el token al cargar la aplicación
    console.log("check check");
    if (isTokenExpired()) {
      // Token expirado, puedes realizar acciones como redirigir al login
      console.log('Token expirado. Redirigiendo al login...');
    }
  }, [isTokenExpired]);

  return router;
};

export default App;
