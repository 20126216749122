import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = ({ items }) => {
  const [activeItems, setActiveItems] = useState([]);

  const handleItemClick = (index) => {
    setActiveItems((prevActiveItems) => {
      if (prevActiveItems.includes(index)) {
        return prevActiveItems.filter((item) => item !== index);
      } else {
        return [...prevActiveItems, index];
      }
    });
  };

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        <Nav.Item>
          {item.subItems ? (
            <Nav.Link
              className="sidebar-link text-dark"
              onClick={() => handleItemClick(index)}
            >
              <span className="me-2">{item.icon}</span>
              {item.label}
              <span className="ml-5">{item.subItems ? '▼' : null}</span>
            </Nav.Link>
          ) : (
            <Nav.Link as={Link} to={item.link} className="sidebar-link text-dark">
              <span className="me-2">{item.icon}</span>
              {item.label}
            </Nav.Link>
          )}
        </Nav.Item>
        {item.subItems && activeItems.includes(index) ? (
          <Nav.Item className="ps-4">
            {renderMenuItems(item.subItems)}
          </Nav.Item>
        ) : null}
      </React.Fragment>
    ));
  };

  return (
    <Nav className="flex-column sidebar shadow" style={{ height: "90vh" }}>
      {renderMenuItems(items)}
    </Nav>
  );
};

export default Sidebar;
