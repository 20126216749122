import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import SucursalesTable from '../Components/Tables/SucursalesTable';  // Asegúrate de tener el componente SucursalesTable creado
import axios from 'axios';
import { Link } from "react-router-dom";

const SucursalesPage = () => {
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de sucursales desde la API
    axios.get('https://api.meycisstore.com/sucursales', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setSucursales(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de sucursales:', error);
      });
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Sucursales</Card.Title>
        <Link to="/admin/sucursales/nueva">
          <Button className="bg-meycs" variant="primary">
            Nueva
          </Button>
        </Link>
      </Card.Header>
      <Card.Body>
        <SucursalesTable sucursales={sucursales} />
      </Card.Body>
    </Card>
  );
};

export default SucursalesPage;
