import React from 'react';
import { Chart,ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';

Chart.register(ArcElement,Tooltip,Legend);

const options = {

  legend: {
    display: true,
    position: 'right',
  },
  responsive: true,
  maintainAspectRatio: true,
};

const BarChart = ({data}) => (
  <div>
    <Pie data={data} options={options} />
  </div>
);

export default BarChart;
