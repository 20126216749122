import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const TallasForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [talla, setTalla] = useState({
    nombre: "",
  });

  const [tocado, setTocado] = useState({
    nombre: false,
  });

  function goBack() {
    navigate(-1);
}

  useEffect(() => {
    // Cargar los datos de la talla para editar
    if (id) {
      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire({
          title:'Validacion',
          text:'No se encontró un token de autorización. La sesión ha terminado',
          icon:'error'
        });
        return;
      }

      axios.get(`https://api.meycisstore.com/tallas/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      })
        .then(response => {
          const { nombre } = response.data;
          setTalla({ nombre });
        })
        .catch(error => {
          console.error('Error al obtener datos de la talla:', error);
          Swal.fire({
            title:'Validacion',
            text:`Error al obtener datos de la talla: ${error}`,
            icon:'error'
          });
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTalla({ ...talla, [name]: value });
    setTocado({ ...tocado, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar campos antes de enviar
    if (!talla.nombre) {
      Swal.fire({
        title:'Validacion',
        text:'Todos los campos son obligatorios',
        icon:'warning'
      });
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      Swal.fire({
        title:'Validacion',
        text:'No se encontró un token de autorización. La sesión ha terminado',
        icon:'error'
      });
      return;
    }

    const apiEndpoint = id ? `https://api.meycisstore.com/tallas/${id}` : 'https://api.meycisstore.com/tallas';

    const axiosMethod = id ? axios.put : axios.post;

    axiosMethod(apiEndpoint, talla, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        const successMessage = id ? 'Se actualizó la talla correctamente' : 'Se creó la talla correctamente';

        Swal.fire({
          title: 'Confirmacion',
          text: successMessage,
          icon: 'success',
        }).then(() => {
          navigate("/admin/tallas");
        });
      })
      .catch(error => {
        const errorMessage = id ? `Error al actualizar la talla: ${error}` : `Error al crear la talla: ${error}`;

        Swal.fire({
          title:'Validacion',
          text: errorMessage,
          icon:'error'
        });
      });
  };

  return (
    <Card className="h-100">
      <Card.Header>{id ? 'Editar Talla' : 'Nueva Talla'}</Card.Header>
      <Card.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                name="nombre"
                value={talla.nombre}
                onChange={handleChange}
                isInvalid={tocado.nombre && !talla.nombre}
              />
              <Form.Control.Feedback type="invalid">
                El nombre es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                {id ? 'Guardar Cambios' : 'Enviar'}
              </Button>
              <Button variant="danger" className="ms-2" onClick={goBack} >
                Regresar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default TallasForm;
