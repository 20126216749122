import React, { useEffect, useState } from 'react';
import { Form,Button } from 'react-bootstrap';
import axios from "axios";
import Swal from 'sweetalert2';
import { FaTimes } from 'react-icons/fa';

const BannersForm = () =>{
    const [images,setImages] = useState([]);
    const [banner,setBanner] = useState(null);
    const [nuevo,setNuevo] = useState({nombre:""})

    useEffect(()=>{
        obtenerBanners();
    },[])

    const obtenerBanners = ()=>{
        axios.get("https://api.meycisstore.com/banners", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            setImages(response.data);
        })
        .catch(error => {
            const errorMessage = `Error al obtener los banner: ${error}`;
            Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
            });
        });
    }

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(!nuevo.nombre)
        {
            Swal.fire({
                title:'Validacion',
                text: 'Es necesario colocar un nombre para la imagen',
                icon:'error'
            });
            return;
        }

        if(!banner)
        {
            Swal.fire({
                title:'Validacion',
                text: 'Es necesario seleccionar una imagen',
                icon:'error'
            });
            return;
        }

        const formData = new FormData();
        formData.append('imagen', banner);
        formData.append('nombre', nuevo.nombre);
        axios.post("https://api.meycisstore.com/banners", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            setNuevo({nombre:''});
            setBanner(null);
            obtenerBanners();
            const successMessage = 'Se creó el banner correctamente';
            Swal.fire({
            title: 'Confirmacion',
            text: successMessage,
            icon: 'success',
            })
        })
        .catch(error => {
            const errorMessage = `Error al crear el banner: ${error}`;
            Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
            });
        });
    }
    
    const handleChange = (e) =>{
        const { name, value, type,files } = e.target;
        if (type === 'file') {
            // Manejar la carga de imagen
            if (files.length > 0) {
                setBanner(files[0]);
            }
        }  else {
            // Manejar otros campos del formulario
            setNuevo({ ...nuevo, [name]: value });
        }
    }

    const handleDeleteImage = (index) =>{
        Swal.fire({
            title:'Confirmacion',
            text:'¿Realmente quiere eliminar la imagen seleccionada?',
            icon:'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#d3a000',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result)=>{
            if (result.isConfirmed) {
            // Si el usuario confirma, realizar la eliminación
            axios
                .delete(`https://api.meycisstore.com/banners/${index}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${localStorage.getItem('token')}`,
                },
                })
                .then(() => {
                setImages((prevImages) =>
                prevImages.filter((banner) => banner.id_banner !== index)
                );
                Swal.fire({
                    title: 'Confirmación',
                    text: 'El banner fue eliminado correctamente.',
                    icon: 'success',
                });
                })
                .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    text: 'No se logró eliminar el banner. Por favor, inténtelo nuevamente.',
                    icon: 'error',
                });
                });
            }
        })
    }
    return(
        <Form className='mt-3' onSubmit={handleSubmit}>
            <p>En esta seccion puedes colocar las imagenes que se mostraran en la pagina principal, procura que sean imagenes <strong>rectangulares</strong>.</p>
            <div className="mt-3 border p-3 mb-3">
            {
                images && images.length > 0 ? (
                    <div className='d-flex' >
                        {images.map((image, index) => (
                            <div className='m-2' style={{ position: 'relative', display: 'inline-block' }} key={index}>
                                <img width={200} src={image.url} alt={`${index}`} />
                                <button
                                    type='button'
                                    onClick={() => handleDeleteImage(image.id_banner)}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        border: 'none',
                                        width: '30px',
                                        height: '30px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FaTimes />
                                </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>SIN IMÁGENES PARA MOSTRAR</p>
                )
            }
            </div>
            <div className="col-md-4">
                <Form.Group className="mb-3" controlId="formImagen">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type="text"
                        name="nombre"
                        onChange={handleChange}
                        value={nuevo.nombre}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formImagen">
                    <Form.Label>Imagen</Form.Label>
                    <Form.Control
                        type="file"
                        name="imagen"
                        onChange={handleChange}
                    />
                </Form.Group>
                {banner && (
                    <img src={URL.createObjectURL(banner)} alt="Imagen seleccionada" style={{ maxWidth: '150px', height: 'auto' }} />
                    
                )}
                <div className="d-flex justify-content-end">
                    <Button variant="primary" type="submit" >
                        Crear Banner
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default BannersForm;