
import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container,Row,Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const ProductosVendedorForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const token = localStorage.getItem('token');
    
    const [producto, setProducto] = useState({
      nombre: "",
      precio_venta: "",
      descripcion: "",
      titulo: "",
      sku: "",
      id_talla: [1],
      id_categoria: [],
    });
  
    const [tocado, setTocado] = useState({
      nombre: false,
      precio_venta: false,
      descripcion: false,
      titulo: false,
      sku: false,
      id_talla: false,
      id_categoria: false,
    });

    function goBack() {
      navigate(-1);
    }

    const [tallas, setTallas] = useState([]); // Para almacenar las tallas disponibles
    const [categorias, setCategorias] = useState([]); // Para almacenar las tallas disponibles
    const [imagen, setImagen] = useState(null);
    useEffect(() => {

      if (id) {
        if (!token) {
          Swal.fire({
            title:'Validacion',
            text:'No se encontró un token de autorización. La sesión ha terminado',
            icon:'error'
          });
          return;
        }
  
        axios.get(`https://api.meycisstore.com/productos/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
        })
          .then(response => {
            const { nombre, precio_venta, descripcion, titulo, sku, id_talla, id_categoria } = response.data;
            setProducto({ nombre, precio_venta, descripcion, titulo, sku, id_talla, id_categoria });
          })
          .catch(error => {
            Swal.fire({
              title:'Validacion',
              text:`Error al obtener datos del producto: ${error}`,
              icon:'error'
            });
          });
      }
      // Obtener las tallas mediante axios y actualizar el estado
      const obtenerTallas = async () => {
        try {
          const response = await axios.get('https://api.meycisstore.com/tallas',{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`,
            }
          });
          setTallas(response.data);
        } catch (error) {
          console.error('Error al obtener las tallas:', error);
        }
      };

      const obtenerCategorias = async () => {
        try {
          const response = await axios.get('https://api.meycisstore.com/categorias',{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`,
            }
          });
          setCategorias(response.data);
        } catch (error) {
          console.error('Error al obtener las tallas:', error);
        }
      };

      obtenerTallas();
      obtenerCategorias();
    }, [id,token]);

    const handleChange = (e) => {
      const { name, value, type, checked,files } = e.target;
    
      if (type === 'checkbox') {
        if (name.startsWith('id_talla')) {
          // Manejar la lógica para tallas seleccionadas/deseleccionadas
          const tallasSeleccionadas = checked
            ? [...producto.id_talla, parseInt(value, 10)]
            : producto.id_talla.filter((id) => id !== parseInt(value, 10));
    
          setProducto({ ...producto, id_talla: tallasSeleccionadas });
        } else if (name.startsWith('id_categoria')) {
          // Manejar la lógica para categorias seleccionadas/deseleccionadas
          const categoriasSeleccionadas = checked
            ? [...producto.id_categoria, parseInt(value, 10)]
            : producto.id_categoria.filter((id) => id !== parseInt(value, 10));
    
          setProducto({ ...producto, id_categoria: categoriasSeleccionadas });
        }
      } else if (type === 'file') {
        // Manejar la carga de imagen
        if (files.length > 0) {
          setImagen(files[0]);
        }
      } else {
        // Manejar otros campos del formulario
        setProducto({ ...producto, [name]: value });
      }
    
      setTocado({ ...tocado, [name]: true });
    };
    
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Validar campos antes de enviar
      const camposObligatorios = ["nombre", "precio_venta"];
      const camposInvalidos = camposObligatorios.filter((campo) => {
          if (campo === "precio_venta" ) {
              return producto[campo] === "" || parseInt(producto[campo]) <= 0;
          } else {
              return !producto[campo];
          }
      });
      
      if (camposInvalidos.length > 0) {
          Swal.fire({
              title: 'Validación',
              text: `Los campos ${camposInvalidos.join(", ")} son obligatorios o tienen un valor inválido.`,
              icon: 'warning'
          });
          return;
      }

      if (camposInvalidos.length > 0) {
          Swal.fire({
              title: 'Validación',
              text: `Los campos ${camposInvalidos.join(", ")} son obligatorios o tienen un valor inválido.`,
              icon: 'warning'
          });
          return;
      }
            
      if (!token) {
        Swal.fire({
          title:'Validación',
          text:'No se encontró un token de autorización. La sesión ha terminado',
          icon:'error'
        });
        return;
      }

      const apiEndpoint = id ? `https://api.meycisstore.com/productos/${id}` : 'https://api.meycisstore.com/productos';
      const axiosMethod = id ? axios.put : axios.post;

      const formData = new FormData();
      formData.append('imagen', imagen);
      formData.append('nombre', producto.nombre);
      formData.append('precio_venta', producto.precio_venta);
      formData.append('descripcion', producto.descripcion);
      formData.append('titulo', producto.titulo);
      formData.append('sku', producto.sku);

      producto.id_categoria.forEach((id_categoria, index) => {
        formData.append(`id_categoria[${index}]`, id_categoria);
      });
      producto.id_talla.forEach((tallaId, index) => {
        formData.append(`id_talla[${index}]`, tallaId);
      });

      axiosMethod(apiEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${token}`,
        },
      })
        .then(response => {
          const successMessage = id ? 'Se actualizó el producto correctamente' : 'Se creó el producto correctamente';
          Swal.fire({
            title: 'Confirmacion',
            text: successMessage,
            icon: 'success',
          }).then(() => {
            navigate("/pos/productos");
          });
        })
        .catch(error => {
          const errorMessage = id ? `Error al actualizar el producto: ${error}` : `Error al crear el producto: ${error}`;
          Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
          });
        });
    };
  
    return (
      <Card className="h-100">
        <Card.Header>{id ? 'Editar Producto' : 'Nuevo Producto'}</Card.Header>
        <Card.Body>
          <Container>
            <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formNombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el nombre"
                    name="nombre"
                    value={producto.nombre}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPrecioVenta">
                  <Form.Label>Precio de Venta</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese el precio de venta"
                    name="precio_venta"
                    value={producto.precio_venta}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formImagen">
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control
                    type="file"
                    name="imagen"
                    onChange={handleChange}
                  />
                </Form.Group>
                {imagen && (
                  <div>
                    <h5>Imagen </h5>
                    <img src={URL.createObjectURL(imagen)} alt="Imagen seleccionada" style={{ maxWidth: '150px', height: 'auto' }} />
                  </div>
                )}
              </Col>

              <Col md={6}>

                <Card className="mb-3">
                  <Card.Header>Tallas</Card.Header>
                  <Card.Body>
                    <Form.Group className="mb-3" controlId="formTallas">
                      {tallas.map((talla) => (
                        <Form.Check
                          key={talla.id_talla}
                          type="checkbox"
                          label={talla.nombre}
                          name={`id_talla_${talla.id_talla}`}
                          value={talla.id_talla.toString()}
                          onChange={handleChange}
                          checked={producto.id_talla.includes(talla.id_talla)}
                          disabled={talla.id_talla === 1 }
                        />
                      ))}
                    </Form.Group>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Categorias</Card.Header>
                  <Card.Body>
                    <Form.Group className="mb-3" controlId="formCategorias">
                      {categorias.map((categoria) => (
                        <Form.Check
                          key={categoria.id_categoria}
                          type="checkbox"
                          label={categoria.nombre}
                          name={`id_categoria${categoria.id_categoria}`}
                          value={categoria.id_categoria.toString()}
                          onChange={handleChange}
                          checked={producto.id_categoria.includes(categoria.id_categoria)}
                          disabled={categoria.id_talla === 1 }
                        />
                      ))}
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

              
  
              <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  {id ? 'Guardar Cambios' : 'Enviar'}
                </Button>
                <Button variant="danger" className="ms-2" onClick={goBack} >
                  Regresar
                </Button>
              </div>
            </Form>
          </Container>
        </Card.Body>
      </Card>
    );
};

export default ProductosVendedorForm;
