import React, { useState } from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ClienteForm = () => {
  const navigate = useNavigate();

  const [cliente, setCliente] = useState({
    nombre: "",
    apellidos: "",
    email: "",
    calle: "",
    numCasa: "",
    colonia: "",
    cp: "",
    ciudad: "",
    estado: "",
  });

  const [tocado, setTocado] = useState({
    nombre: false,
    apellidos: false,
    email: false,
    calle: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCliente({ ...cliente, [name]: value });
    setTocado({ ...tocado, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      Swal.fire({
        title:'Validación',
        text:'No se encontró un token de autorización',
        icon:'warning'
      });
      return;
    }

    axios.post('https://api.meycisstore.com/clientes', cliente, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        Swal.fire({
          title:'Confirmacion',
          text:`Se creó el cliente correctamente`,
          icon:'success'
        }).then(()=>{
          navigate('/admin/clientes');
        });
      })
      .catch(error => {
        Swal.fire({
          title:'Confirmacion',
          text:`Error al crear el cliente:${error}`,
          icon:'error'
        });
      })
  };

  return (
    <Card className="h-100">
      <Card.Header>Nuevo Cliente</Card.Header>
      <Card.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formNombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el nombre"
                    name="nombre"
                    value={cliente.nombre}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formApellidos">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese los apellidos"
                    name="apellidos"
                    value={cliente.apellidos}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingrese el correo electrónico"
                    name="email"
                    value={cliente.email}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPass">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Ingrese la contraseña"
                        name="pass"
                        value={cliente.pass}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formCalle">
                  <Form.Label>Calle</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese la calle"
                    name="calle"
                    value={cliente.calle}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formNumCasa">
                  <Form.Label>Número de Casa</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el número de casa"
                    name="numCasa"
                    value={cliente.numCasa}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formColonia">
                  <Form.Label>Colonia</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese la colonia"
                    name="colonia"
                    value={cliente.colonia}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formCP">
                  <Form.Label>Código Postal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el código postal"
                    name="cp"
                    value={cliente.cp}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formCiudad">
                  <Form.Label>Ciudad</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese la ciudad"
                    name="ciudad"
                    value={cliente.ciudad}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEstado">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el estado"
                    name="estado"
                    value={cliente.estado}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default ClienteForm;
