import React, { useState } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const SucursalesForm = () => {
    const navigate = useNavigate();

    const [sucursal, setSucursal] = useState({
      nombre: "",
      direccion: "",
    });
  
    const [tocado, setTocado] = useState({
      nombre: false,
      direccion: false,
    });

    function goBack() {
      navigate(-1);
  }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setSucursal({ ...sucursal, [name]: value });
      setTocado({ ...tocado, [name]: true });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();

      // Validar campos antes de enviar
      if (!sucursal.nombre || !sucursal.direccion) {
        Swal.fire({
          title:'Validacion',
          text:'Todos los campos son obligatorios',
          icon:'warning'
        });
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire({
          title:'Validacion',
          text:'No se encontró un token de autorización. La sesion ha terminado',
          icon:'error'
        });
        return;
      }

      axios.post('https://api.meycisstore.com/sucursales', sucursal, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      })
        .then(response => {
          Swal.fire({
            title: 'Confirmacion',
            text: 'Se creó la sucursal correctamente',
            icon: 'success',
          }).then((result) => {
            navigate("/admin/sucursales");
          });
        })
        .catch(error => {
          Swal.fire({
            title:'Validacion',
            text:`Error al crear la sucursal:${error}`,
            icon:'error'
          });
        });
    };
  
    return (
      <Card className="h-100">
        <Card.Header>Nueva Sucursal</Card.Header>
        <Card.Body>
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el nombre"
                  name="nombre"
                  value={sucursal.nombre}
                  onChange={handleChange}
                  isInvalid={tocado.nombre && !sucursal.nombre}
                />
                <Form.Control.Feedback type="invalid">
                  El nombre es obligatorio.
                </Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group className="mb-3" controlId="formDireccion">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese la dirección"
                  name="direccion"
                  value={sucursal.direccion}
                  onChange={handleChange}
                  isInvalid={tocado.direccion && !sucursal.direccion}
                />
                <Form.Control.Feedback type="invalid">
                  La dirección es obligatoria.
                </Form.Control.Feedback>
              </Form.Group>
  
              <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Enviar
                </Button>
                <Button variant="danger" className="ms-2" onClick={goBack} >
                  Regresar
                </Button>
              </div>
            </Form>
          </Container>
        </Card.Body>
      </Card>
    );
  };
  
  export default SucursalesForm;
  