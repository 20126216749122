import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import DataTable from "../Components/Shared/DataTable";
import { useNavigate, useParams } from "react-router-dom";

const InventariosForm = () => {
    const {id} = useParams();
    const [nombre, setNombre] = useState("");
    const [inventarios, setInventarios] = useState([]);
    const [cambios, setCambios] = useState([]);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.meycisstore.com/inventario/${id}`,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${localStorage.getItem('token')}`,
                    }
                });
                setNombre(response.data.nombre_producto);
                setInventarios(response.data.inventario);
            } catch (error) {
                console.error(`Error al obtener el inventario del producto: ${error.message}`);
                Swal.fire({
                    title: "Validación",
                    text: `Error al obtener el inventario del producto: ${error.message}`,
                    icon: "error",
                });
            }
        };
        fetchData();
    }, [id]);

    const handleCellChange = (id_sucursal, id_talla, cantidad) => {
        if(cantidad < 0)
        {
            Swal.fire({
                title:'Advertencia',
                text:'La cantidad no debe ser menos a 0',
                icon:'warning'
            });
            return;
        }
        setInventarios((prevInventarios) => {
            const nuevosInventarios = prevInventarios.map((item) => {
                if (item.id_sucursal === id_sucursal) {
                    const nuevosInventario = item.inventario.map((producto) => {
                        if (producto.id_sucursal === id_sucursal && producto.id_talla === id_talla) {
                            return {
                                ...producto,
                                cantidad: cantidad,
                            };
                        } else {
                            return producto;
                        }
                    });
        
                    return {
                        ...item,
                        inventario: nuevosInventario, // Actualiza la propiedad inventario
                    };
                } else {
                    return item;
                }
            });
        
            return nuevosInventarios;
        });
        
    
        setCambios((prevCambios) => {
            const index = prevCambios.findIndex(
                (cambio) =>
                    cambio.id_sucursal === id_sucursal &&
                    cambio.id_talla === id_talla
            );
    
            if (index !== -1) {
                const nuevosCambios = [...prevCambios];
                nuevosCambios[index].cantidad = cantidad;
                return nuevosCambios;
            } else {
                return [
                    ...prevCambios,
                    {
                        id_sucursal: id_sucursal,
                        id_talla: id_talla,
                        cantidad: cantidad,
                    },
                ];
            }
        });
    };
    
    const enviarProductos = async ()=>{
        try {
            const response = await axios.put(`https://api.meycisstore.com/inventario/${id}`,cambios,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${localStorage.getItem('token')}`,
                }
            });
            console.log(response.data);
            const ruta = user.id_rol === 1 ? 'admin' : 'pos';
            Swal.fire({
                title:'Confirmacion',
                text:'Se actualizo el inventario correctamente',
                icon:'success'
            });
            navigate(`/${ruta}/inventario`);
        } catch (error) {
            console.error(`Error al obtener el inventario del producto: ${error.message}`);
            Swal.fire({
                title: "Validación",
                text: `Error al actualizar el inventario del producto: ${error.message}`,
                icon: "error",
            });
        }
    }

    const columns = [
        {
            Header: "Talla",
            accessor: "nombre",
        },
        {
            Header: "Cantidad",
            accessor: "cantidad",
            Cell: ({ value, row }) => {
                const [inputValue, setInputValue] = useState(value);

                const handleChange = (e) => {
                    const { value: newInputValue } = e.target;
                    setInputValue(newInputValue);
                    handleCellChange(row.original.id_sucursal, row.original.id_talla, newInputValue);
                };

                return (
                    <Form.Group controlId={`formCantidad_${row.original.id_sucursal}_${row.original.id_talla}`}>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad"
                            name={`cantidad_${row.original.id_sucursal}_${row.original.id_talla}`}
                            value={inputValue}
                            onChange={handleChange}
                        />
                    </Form.Group>
                );
            },
        },
    ];

    return (
        <Card className="h-100">
            <Card.Header>Inventario {nombre}</Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        {inventarios ? (
                            inventarios.map((item, index) => (
                                <Col key={index} md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>{item.nombre}</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <DataTable
                                                columns={columns}
                                                data={item.inventario}
                                                searchParam={true}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        ) : (
                            <p>No hay inventarios disponibles.</p>
                        )}
                    </Row>
                </Container>
            </Card.Body>
            <Card.Footer>
                <div className="d-flex justify-content-end">
                    <Button onClick={enviarProductos}>
                        Guardar Datos
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default InventariosForm;
