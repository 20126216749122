import React, { useState } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CategoriasForm = () => {
  const navigate = useNavigate();
  const [imagen,setImagen] = useState(null);
  const [categoria, setCategoria] = useState({
    nombre: "",
    descripcion: "",
  });

  const [tocado, setTocado] = useState({
    nombre: false,
    descripcion: false,
  });

  function goBack() {
    navigate(-1);
  }

  const handleChange = (e) => {
    // Actualiza el estado de la categoría al escribir en los campos del formulario
    const { name, value,type,files } = e.target;
    if (type === 'file') {
      // Manejar la carga de imagen
      if (files.length > 0) {
          setImagen(files[0]);
          setCategoria({ ...categoria, imagenurl:'' });
      }
    }  else {
        // Manejar otros campos del formulario
        setCategoria({ ...categoria, [name]: value });
        setTocado({ ...tocado, [name]: true });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      Swal.fire({
        title:'Advertencia',
        text:'No se encontro un token de autorizaciòn',
        icon:'error'
      });
      return;
    }

    const datosCategoria = new FormData();
    datosCategoria.append('nombre',categoria.nombre)
    datosCategoria.append('descripcion',categoria.descripcion)
    datosCategoria.append('imagen',imagen)

    axios.post('https://api.meycisstore.com/categorias', datosCategoria, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        setCategoria(response.data);
        Swal.fire({
          title:'Advertencia',
          text:'Se creó la categoría correctamente',
          icon:'success'
        }).then(()=>{
          navigate('/admin/categorias');
        });
      })
      .catch(error => {
        Swal.fire({
          title:'Advertencia',
          text:`Error al crear la categoría: ${error} `,
          icon:'error'
        });
      })
  };

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Nueva Categoría</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                name="nombre"
                value={categoria.nombre}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese la descripción"
                name="descripcion"
                value={categoria.descripcion}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formImagen">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                name="imagen"
                onChange={handleChange}
              />
            </Form.Group>
            {imagen && (
              <div>
                {imagen && (
                    <img src={URL.createObjectURL(imagen)} alt="Imagen seleccionada" style={{ maxWidth: '200px', height: 'auto' }} />
                )}
              </div>
            )}

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" disabled={!categoria.nombre || !categoria.descripcion}>
                Enviar
              </Button>
              <Button variant="danger" className="ms-2" onClick={goBack}>
                Regresar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default CategoriasForm;
