import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import TallasTable from '../Components/Tables/TallasTable';  // Asegúrate de tener el componente TallasTable creado
import axios from 'axios';
import { Link } from "react-router-dom";

const TallasPage = () => {
  const [tallas, setTallas] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de tallas desde la API
    axios.get('https://api.meycisstore.com/tallas', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setTallas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de tallas:', error);
      });
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Tallas</Card.Title>
        <Link to="/admin/tallas/nuevo">
          <Button className="bg-meycs" variant="primary">
            Nueva
          </Button>
        </Link>
      </Card.Header>
      <Card.Body>
        <TallasTable tallas={tallas} />
      </Card.Body>
    </Card>
  );
};

export default TallasPage;
