import React, { useState } from 'react';
import { Tab, Tabs,Card,Container } from 'react-bootstrap';

import BannersForm from "../Components/Tabs/BannersForm";
import AnunciosForm from "../Components/Tabs/AnunciosForm";
import AcercadeForm from '../Components/Tabs/AcercadeForm';
import PreguntasFrecuentesForm from '../Components/Tabs/PreguntasFrecuentesForm';
import Conocenos from '../Components/Tabs/Conocenos';
import PoliticaEnvio from '../Components/Tabs/PoliticaEnvio';
import PoliticaReembolso from '../Components/Tabs/PoliticaReembolso';
import PoliticaPrivacidad from '../Components/Tabs/PoliticaPrivacidad';
import TerminosCondiciones from '../Components/Tabs/TerminosCondiciones';
import TerminosServicio from '../Components/Tabs/TerminosServicio';
import ComoComprarForm from "../Components/Tabs/ComoComprarForm";
import DomicilioForm from "../Components/Tabs/DomicilioForm";
import PreciosEnvioForm from "../Components/Tabs/PreciosEnvioForm";

const WebsitePage = () => {
  const [tabKey, setTabKey] = useState('tab1');

  const handleTabSelect = (key) => {
    setTabKey(key);
  };
  
  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Pagina Web</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
        <Tabs id="controlled-tabs" activeKey={tabKey} onSelect={handleTabSelect}>
          <Tab eventKey="tab1" title="Banners">
            <BannersForm/>
          </Tab>
          <Tab eventKey="tab2" title="Texto Conocenos">
            <Conocenos></Conocenos>
          </Tab>
          <Tab eventKey="tab3" title="Preguntas Frecuentes">
            <PreguntasFrecuentesForm></PreguntasFrecuentesForm>
          </Tab>
          <Tab eventKey="tab4" title="Anuncio">
            <AnunciosForm></AnunciosForm>
          </Tab>
          <Tab eventKey="tab5" title="Nosotros">
            <AcercadeForm></AcercadeForm>
          </Tab>
          <Tab eventKey="tab6" title="Politicas de Envio">
            <PoliticaEnvio></PoliticaEnvio>
          </Tab>
          <Tab eventKey="tab7" title="Politica de Reembolso">
            <PoliticaReembolso></PoliticaReembolso>
          </Tab>
          <Tab eventKey="tab8" title="Politicas de Privacidad">
            <PoliticaPrivacidad></PoliticaPrivacidad>
          </Tab>
          <Tab eventKey="tab9" title="Terminos y condiciones">
            <TerminosCondiciones></TerminosCondiciones>
          </Tab>
          <Tab eventKey="tab10" title="Terminos del servicio">
            <TerminosServicio></TerminosServicio>
          </Tab>
          <Tab eventKey="tab11" title="Como Comprar">
            <ComoComprarForm></ComoComprarForm>
          </Tab>
          <Tab eventKey="tab12" title="Texto Domicilio">
            <DomicilioForm></DomicilioForm>
          </Tab>
          <Tab eventKey="tab13" title="Configuracion de envio y compra minima">
            <PreciosEnvioForm></PreciosEnvioForm>
          </Tab>
        </Tabs>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default WebsitePage;
