import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import CortesTable from '../Components/Tables/CortesTable';  // Asegúrate de tener el componente CortesTable creado
import axios from 'axios';

const CortesPage = () => {
  const [cortes, setCortes] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de cortes desde la API
    axios.get('https://api.meycisstore.com/cortes', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setCortes(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de cortes:', error);
      });
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Cortes de Caja</Card.Title>
      </Card.Header>
      <Card.Body>
        <CortesTable cortes={cortes} />
      </Card.Body>
    </Card>
  );
};

export default CortesPage;
