import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Form, Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const ModalRetiro = ({ show, onHide }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const [montoTarjetas, setMontoTarjetas] = useState('');

    const [valores, setValores] = useState({
        mil: 0,
        quinientos: 0,
        doscientos: 0,
        cien: 0,
        cincuenta: 0,
        veinte: 0,
        diez: 0,
        cinco: 0,
        uno: 0,
        cincuentaCentavos: 0,
    });
    
    const valoresNumericos = {
    mil: 1000,
    quinientos: 500,
    doscientos: 200,
    cien: 100,
    cincuenta: 50,
    veinte: 20,
    diez: 10,
    cinco: 5,
    uno: 1,
    cincuentaCentavos: 0.50,
    };
    
    const [total, setTotal] = useState(0);

    const handleLogout = async () => {
        axios.post('https://api.meycisstore.com/logout',user, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':`${localStorage.getItem('token')}`,
        },
        }).then((response)=>{
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate("/");
        }).catch((error)=>{
        Swal.fire({
            title:'Advertencia',
            text:error.response.data.message,
            icon:'warning'
        })
        });;
    };

    const handleInputChange = (label, value) => {
        const updatedValores = {
        ...valores,
        [label]: value,
        };
    
        setValores(updatedValores);
    
        // Calcular el total
        const totalCalculado = Object.entries(updatedValores).reduce(
            (accumulator, [label, value]) => accumulator + parseFloat(valoresNumericos[label]) * value,
            0
        );
    
        setTotal(totalCalculado.toFixed(2));
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        // Validar que todos los campos estén llenos
        if ( total < 0 || montoTarjetas < 0 ) {
            Swal.fire({
                icon: 'error',
                title: 'Campos obligatorios',
                text: 'Todos los campos son obligatorios. Por favor, complete todos los campos.',
            });
            return;
        }

        // Crear objeto datosRetiro
        const datosRetiro = {
            id_user: user.id_user,
            id_sucursal: user.id_sucursal,
            efectivo: total,
            tarjetas: montoTarjetas,
            tipo_corte:'Individual',
            desloce:valores
        };

        // Enviar solicitud axios
        axios.post("https://api.meycisstore.com/cortes", datosRetiro,{
            headers:{
                "Content-Type":'application/json',
                Authorization:token
            }
        })
            .then(response => {
                // Mostrar mensaje de éxito

                Swal.fire({
                    icon: 'success',
                    title: 'Corte de caja exitoso',
                    text: 'El corte de caja se ha registrado correctamente. Se Cerrara la Sesion'
                }).then((response)=>{
                    console.log(response);
                    handleLogout();
                });

                // Puedes realizar otras acciones después de un éxito, como cerrar el modal, etc.
            })
            .catch(error => {
                // Mostrar mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: 'Error en el corte de caja',
                    text: 'Hubo un error al procesar el corte de caja. Por favor, inténtelo de nuevo.',
                });
            });
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Corte de caja</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form key={'form-retiro'} onSubmit={handleSubmit}>
                    <Row key={'retiro-row'} className="mb-3">
                        {
                            Object.entries(valores).map(([label, valorInicial]) => (
                                <>
                                    <Col sm={6} className='mb-3 text-center'>
                                        <Form.Label key={`label-${label}`}>{`$${valoresNumericos[label]}`}</Form.Label>
                                    </Col>
                                    <Col sm={6} className='mb-3'>
                                        <Form.Group controlId={label}>
                                            <Form.Control
                                            key={label}
                                                type="number"
                                                value={valores[label]}
                                                onChange={(e) => handleInputChange(label, e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col> 
                                </>
                            ))
                        }
                    </Row>
                    <Form.Group className='row mb-3' controlId="formTarjetas">
                        <Col sm={5}>
                            <Form.Label>Efectivo Total:</Form.Label>
                        </Col>
                        <Col sm={7}>
                            <Form.Control
                                type="number"
                                placeholder="Ingrese"
                                value={total}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className='row mb-3' controlId="formTarjetas">
                        <Col sm={5}>
                            <Form.Label>Tarjetas:</Form.Label>
                        </Col>
                        <Col sm={7}>
                            <Form.Control
                                type="number"
                                placeholder="Ingrese el monto total"
                                value={montoTarjetas}
                                onChange={(e) => setMontoTarjetas(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <div className='d-flex justify-content-end'>
                        <Button variant="primary" type='submit'>Generar Corte</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRetiro;
