import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const CategoriasFormEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const [categoria, setCategoria] = useState({
    id: "",
    nombre: "",
    descripcion: "",
  });

  const [tocado, setTocado] = useState({
    nombre: false,
    descripcion: false,
  });

  const [imagen,setImagen] = useState(null);

  const handleChange = (e) => {
    const { name, value,type,files } = e.target;
    if (type === 'file') {
      // Manejar la carga de imagen
      if (files.length > 0) {
          setImagen(files[0]);
          setCategoria({ ...categoria, imagenurl:'' });
      }
    }  else {
      // Manejar otros campos del formulario
      setCategoria({ ...categoria, [name]: value });
      setTocado({ ...tocado, [name]: true });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!token) {
      Swal.fire({
        title:'Advertencia',
        text:'No se encontro un token de autorizaciòn',
        icon:'error'
      });
      return;
    }

    const fd = new FormData();
    fd.append('imagen',imagen);
    fd.append('nombre',categoria.nombre);
    fd.append('descripcion',categoria.descripcion)

    axios.put(`https://api.meycisstore.com/categorias/${id}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        Swal.fire({
          title:'Confirmación',
          text:'Se creó la categoría correctamente',
          icon:'success'
        }).then(()=>{
          navigate('/admin/categorias');
        });
      })
      .catch(error => {
        Swal.fire({
          title:'Error',
          text:`Error al editar la categoría: ${error}`,
          icon:'error'
        });
      });
  };

  useEffect(() => {
    const categoryId = id;

    axios.get(`https://api.meycisstore.com/categorias/${categoryId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        const { id, ...categoryInfo } = response.data;
        setCategoria(categoryInfo);
      })
      .catch(error => {
        Swal.fire({
          title:'Error',
          text:'Error al obtener datos de la categoría.',
          icon:'error'
        }).then(()=>{
          navigate('/admin/categorias');
        });
      });
  }, [id, token, navigate]);

  function goBack() {
    navigate(-1);
  }

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Editar categoría</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                name="nombre"
                value={categoria.nombre}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese la descripción"
                name="descripcion"
                value={categoria.descripcion}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formImagen">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                name="imagen"
                onChange={handleChange}
              />
            </Form.Group>
            {imagen && (
              <div>
                {imagen && (
                    <img src={URL.createObjectURL(imagen)} alt="Imagen seleccionada" style={{ maxWidth: '200px', height: 'auto' }} />
                )}
              </div>
            )}
            {categoria.imagenurl && (
              <div>
                <img src={categoria.imagenurl} alt="Imagen seleccionada" style={{ maxWidth: '200px', height: 'auto' }} />
              </div>
            )}
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" disabled={!categoria.nombre || !categoria.descripcion}>
                Guardar Cambios
              </Button>
              <Button variant="danger" className="ms-2" onClick={goBack}>
                Regresar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default CategoriasFormEdit;
