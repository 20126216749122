import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import NumberCard from "../Components/Shared/NumberCard";
import BarChart from "../Components/Shared/BarChart";
import CircleChart from "../Components/Shared/CircleChart";

export const DashboardPage = () => {
  const [stats,setStats]=useState({
    cantidad_ventas : 0,
    ingresos_generados : 0,
    ticket_promedio:0,
    total_utilidad:0
  });
  const [showBar,setShowBar] = useState(false);
  const [salesData,setSalesData] = useState();
  const [pieData,setPieData] = useState();

  useEffect( ()=>{
    axios.get('https://api.meycisstore.com/stats', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setStats(response.data.statsVentas[0]);
        setSalesData(response.data.ventasbar);
        setPieData(response.data.pieDataset[0]);
        setShowBar(true);
      })
      .catch(error => {
        console.error('Error al obtener datos de cortes:', error);
      });
  },[]);
  
  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Dashboard</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className='mb-5'>
          <Col sm={12} md={3}>
            <NumberCard title="# Ventas" targetValue={stats.cantidad_ventas} />
          </Col>
          <Col sm={12} md={3}>
            <NumberCard title="Ingreso Total" targetValue={stats.ingresos_generados} />
          </Col>
          <Col sm={12} md={3}>
            <NumberCard title="Ganancia" targetValue={stats.total_utilidad} />
          </Col>
          <Col sm={12} md={3}>
            <NumberCard title="Ticket Promedio" targetValue={stats.ticket_promedio} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} >
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Ventas por mes</Card.Title>
              </Card.Header>
              <Card.Body>
                { showBar ? <BarChart dataChar={salesData} /> : null }
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} >
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Ventas por Sucursal</Card.Title>
              </Card.Header>
              <Card.Body>
              { showBar ? <CircleChart data={pieData} /> : null }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
