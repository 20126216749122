import axios from 'axios';
import React, { useState } from 'react';
import { Modal,Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const ModalVerificarPrecio = ({ show, onHide }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(0);
    const [producto, setProducto] = useState({
        nombre:'',
        talla:'',
        imagen:''
    });
    const user = JSON.parse(localStorage.getItem('user'))

    const searchProducts = (tmpSearch) => {
        console.log(producto);
        const path = tmpSearch.length === 9 ? 'searchsku' : 'search';
        const params = tmpSearch.length === 9 
        ? {id_sucursal:user.id_sucursal,sku:tmpSearch} 
        : { id_sucursal:user.id_sucursal, nombre:tmpSearch };

        const token = localStorage.getItem('token');
        axios.get(`https://api.meycisstore.com/productos/${path}`,{
            headers:{
                "Content-Type":"application/json",
                "Authorization":`${token}`
            },
            params:params
        }).then(results=>{
            const result = results.data[0];

            if( result)
            {
                setSearchResults(result.precio_venta);
                const productoSeleccionado = {
                    nombre: result.nombre_producto,
                    talla: result.nombre_talla,
                    imagen: result.imagen
                }
                setProducto(productoSeleccionado);
                console.log(producto);
            }
        }).catch((error)=>{
            console.log(error);
            Swal.fire({
                title:'Advertencia',
                text:'No se encontro ningun producto',
                icon:'info'
            })
        })

    };
 
    // Función para manejar el cambio en el input
    const handleInputChange = async (e) => {
        e.preventDefault();
        const value = e.target.value;
        await setSearchTerm(value);
        // Realizar la búsqueda automáticamente si hay más de 3 caracteres
        if (value.length >= 3) {
            searchProducts(value);
        } else {
            setSearchResults(0);
            const limpiarProducto = { nombre:'',talla:'',imagen:'' }
            setProducto(limpiarProducto);
        }
    };

    const beforeHide = () =>{
        setSearchResults(0);
        const limpiarProducto = { nombre:'',talla:'',imagen:'' }
        setProducto(limpiarProducto);
        setSearchTerm('');
        onHide();
    }

    return (
        <Modal show={show} onHide={beforeHide}>
        <Modal.Header closeButton>
            <Modal.Title>Verificar Precio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className='mb-5' controlId="formSearch">
                <Form.Label>Buscar Producto:</Form.Label>
                <Form.Control
                type="text"
                placeholder="Ingrese el nombre o escanear el producto"
                value={searchTerm}
                onChange={handleInputChange}
                />
            </Form.Group>
            <div>
                <p className='h4'> <span className='h1'>{producto.nombre}</span> {producto.talla} </p>
                <p className="h1 text-center">$ {searchResults.toFixed(2)} </p>
                    
                <div className="imagen">
                    <img src={producto.imagen} alt="" />
                </div>
                <div className="imagen text-center">
                    {
                        producto.imagen === '' ? 
                        <img src="https://9931890f7c.cbaul-cdnwnd.com/d04f2c1531f2b3361e7500a0d9ec6946/200000077-87966888e4/Imagen-no-disponible.png?" alt="" />
                        :
                        <img src={producto.imagen} alt="" />
                    }
                    
                </div>

            </div>
        </Modal.Body>
        </Modal>
    );
};

export default ModalVerificarPrecio;
