import React, { useEffect, useMemo, useState,useRef } from 'react';
import DataTable from '../Shared/DataTable';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash, FaPrint } from 'react-icons/fa'; // Importa los íconos que desees utilizar
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';
import Barcode from 'react-barcode';
import {toPng} from 'html-to-image';

const ProductosTable = ({ productos }) => {
  const navigate = useNavigate();
  const [tallas, setTallas] = useState([]);
  const refEtiqueta = useRef(null);
  const [datosEtiqueta,setDatosEtiqueta] = useState({});
  const [productosList, setProductos] = useState(productos);
  

  useEffect(()=>{
    const handleFetchTallas = async ()=>{
      try {
        const response = await axios.get('https://api.meycisstore.com/tallas',{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${localStorage.getItem('token')}`,
          }
        });
        setTallas(response.data);
      } catch (error) {
        console.error('Error al obtener las tallas:', error);
      }
    }

    handleFetchTallas();
  },[])

  useEffect(()=>{
    setProductos(productos);
  },[productos])

  const columns = useMemo(
    () => [
      {
        Header: 'ID Producto',
        accessor: 'id_producto',
      },
      {
        Header: 'Imagen',
        accessor: 'imagen',
        Cell: ({ row }) => {
          return row.original.imagen ? (
            <img src={row.original.imagen} alt="Imagen" style={{ width: '50px', height: '50px' }} />
          ) : (
            <img src="https://9931890f7c.cbaul-cdnwnd.com/d04f2c1531f2b3361e7500a0d9ec6946/200000077-87966888e4/Imagen-no-disponible.png?ph=9931890f7c" alt="Imagen no disponible" style={{ width: '50px', height: '50px' }} />
          );
        },
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Precio Venta',
        accessor: 'precio_venta',
        Cell:({row}) =>{
          return row.original.precio_venta.toFixed(2);
        }
      },
      {
        Header: 'Precio Compra',
        accessor: 'precio_compra',
        Cell:({row}) =>{
          return row.original.precio_compra.toFixed(2);
        }
      },
      {
        Header: 'Ganancia',
        accessor: 'ganancia',
        Cell: ({ row }) => {
          const precioVenta = row.original.precio_venta || 0;
          const precioCompra = row.original.precio_compra || 0;
          const ganancia = precioVenta - precioCompra;
          return ganancia.toFixed(2);
        },
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ row }) => {
          const statusLabel = row.original.status === 1 ? 'Activo' : 'Agotado';
          return statusLabel;
        },
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {

          const handlePrintProducto = async (productoId) => {
            const detalleProducto = await axios.get(`https://api.meycisstore.com/productos/${productoId}`, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}`,
                  },
              });
        
              if (detalleProducto.status !== 200) {
                Swal.fire({
                  title:'Advertencia',
                  icon:'warning',
                  text:'No es posible imprimir etiquetas en este momento'
                });
                return;
              }

              const tallasProducto = tallas.filter((talla)=>  detalleProducto.data.id_talla.includes(talla.id_talla) );
              withReactContent(Swal).fire({
                title: 'Imprimir Etiqueta',
                html: (
                  <Form id="miFormulario">
                    <Form.Group controlId="talla" className='mb-3'>
                      <Form.Label>Talla</Form.Label>
                      <Form.Select>
                        {
                          tallasProducto.map((talla)=> <option key={talla.id_talla} value={talla.id_talla} >{talla.nombre} </option> )
                        }
                      </Form.Select>
                    </Form.Group>
                  </Form>
                ),
                showCancelButton: true,
                confirmButtonText: 'Imprimir',
                cancelButtonText: 'Cancelar',
                preConfirm: async () => {
                  const opcion = document.getElementById('talla').value;
                  const sku = productoId.toString().padStart(6,'0') + opcion.toString().padStart(3,'0');
                  setDatosEtiqueta({
                    sku:sku,
                    precio:detalleProducto.data.precio_venta.toFixed(2),
                    texto:`${detalleProducto.data.nombre}`,
                  });
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  toPng(refEtiqueta.current)
                  .then((dataUrl) => {
                    // Crear un enlace para descargar la imagen
                    var link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'etiqueta.png';
                    link.click();
                  })
                  .catch((error) => {
                    console.error('Error al generar la imagen:', error);
                  }).finally(()=>{
                    setDatosEtiqueta({});
                  });
                }
              });
          };
          const handleEditProducto = (producto) => {
            navigate(`/admin/productos/editar/${producto.id_producto}`);
          };

          return(
            <div className='d-flex justify-content-around'>
              <Button variant="warning" size="sm" onClick={() => handlePrintProducto(row.original.id_producto)}>
                <FaPrint />
              </Button>
              <Button variant="primary" size="sm" onClick={() => handleEditProducto(row.original)}>
                <FaEdit />
              </Button>
              <Button variant="danger" size="sm" onClick={() => handleDeleteProducto(row.original.id_producto)}>
                <FaTrash />
              </Button>
            </div>
          )
        },
      },
    ],
    [navigate,tallas,refEtiqueta]
  );

  const handleDeleteProducto = (productoId) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará el producto. No podrás deshacer este cambio.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Si el usuario confirma, realizar la eliminación
        const token = localStorage.getItem('token');
        axios.delete(`https://api.meycisstore.com/productos/${productoId}`,
        {
          headers:{
            "Content-Type":'application/json',
            Authorization:token
          }
        })
          .then(() => {
            setProductos((prevProductos) =>
            prevProductos.filter((producto) => producto.id_producto !== productoId)
            );
            Swal.fire({
              title: 'Confirmación',
              text: 'El producto fue eliminado correctamente.',
              icon: 'success',
            });
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: `No se logró eliminar el producto: ${error}. Por favor, inténtelo nuevamente.`,
              icon: 'error',
            });
          });
      }
    });
  };

  return (
  <>
    <DataTable columns={columns} data={productosList} />
    <div style={{paddingTop:'440px'}} className='row' ref={refEtiqueta}>
      {
        datosEtiqueta.texto ? 
        <div style={{marginLeft:'550px'}} className='col-md-6 pt-5'>
          <p className='text-center pt-5' style={{fontSize:'30px'}}>{datosEtiqueta.texto} </p>
          <p style={{fontSize:'40px',marginBottom:'75px'}} className='text-center'>$ {datosEtiqueta.precio} </p>
          <div className='d-flex justify-content-center' >
            <Barcode value={datosEtiqueta.sku} width={3} height={35} />
          </div>
        </div>: null
      }
    </div>
  </>)
};

export default ProductosTable