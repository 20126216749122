import React, { useEffect, useState } from 'react';
import { Form,Button } from 'react-bootstrap';
import axios from "axios";
import Swal from 'sweetalert2';

const AnunciosForm = () =>{
    const [anuncio,setAnuncio] = useState({valor:"",clave:"anuncio"});
    const [actual,setActual] = useState({});

    useEffect(()=>{
        obtenerAnuncio();
    },[])

    const obtenerAnuncio = ()=>{
        axios.get("https://api.meycisstore.com/configuracion/anuncio", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            if (response.data) {
                setActual(response.data);
            }
        })
        .catch(error => {
            const errorMessage = `Error al obtener los banner: ${error}`;
            Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
            });
        });
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        anuncio.valor.trimEnd();
        if(!anuncio.valor)
        {
            Swal.fire({
                title:'Validacion',
                text: 'El anuncio no puede estar vacio',
                icon:'error'
            });
            return;
        }

        axios.post("https://api.meycisstore.com/configuracion", anuncio, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            //setAnuncio({ ...anuncio, valor: '' });
            obtenerAnuncio();
            const successMessage = 'Se actualizo el anuncio correctamente';
            Swal.fire({
            title: 'Confirmacion',
            text: successMessage,
            icon: 'success',
            })
        })
        .catch(error => {
            const errorMessage = `Error al colocar el anuncio: ${error}`;
            Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
            });
        });
    }
    
    const handleChange = (e) =>{
        const { name, value } = e.target;
        setAnuncio({ ...anuncio, [name]: value });
    }

    
    return(
        <Form className='mt-3' onSubmit={handleSubmit}>
            <p>
                En esta seccion puedes controlar el anuncio que aparece sobre el banner, utilizalo para dar algun anuncio importante a los clientes.
                <br/>
                PD: solo puede aparecer uno a la vez.
            </p>
            <div className="mt-3 border p-3 mb-3">
                {
                    actual.valor  ? <p className='alert alert-success text-center'> {actual.valor} </p> : <p> NO HAY ANUNCIO PARA MOSTRAR </p>
                }
            </div>
            <div className="col-md-4">
                <Form.Group className="mb-3" controlId="formImagen">
                    <Form.Label>Anuncio</Form.Label>
                    <Form.Control
                        type="text"
                        name="valor"
                        onChange={handleChange}
                        value={anuncio.valor}
                    />
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" type="submit" >
                        Enviar Anuncio
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default AnunciosForm;