import React, { useMemo } from 'react';
import DataTable from '../Shared/DataTable';

const ChecadorTable = ({registrosChecador}) => {

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'nombre_usuario',
      },
      {
        Header: 'Accion',
        accessor: 'accion',
      },
      {
        Header: 'Fecha',
        accessor: 'fecha',
        Cell:({row})=>{
          return row.original.fecha;
        }
      },
      {
        Header: 'Hora',
        accessor: 'hora',
      },
      // Agrega más columnas según la estructura de tus datos
    ],
    []
  );

  return <DataTable columns={columns} data={registrosChecador} />;
};

export default ChecadorTable;