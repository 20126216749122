import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

const ModalRetiro = ({ show, onHide }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token');

    // Usar un estado separado para cada campo del formulario
    const [montoRetiro, setMontoRetiro] = useState('');
    const [motivo, setMotivo] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if( !montoRetiro || !motivo )
        {
            Swal.fire({
                title:'Advertencia',
                text:'Es necesario especificar un monto y motivo del retiro de caja',
                icon:'warning'
            });

            return;
        }

        const datosRetiro = {
            id_user: user.id_user,
            id_sucursal: user.id_sucursal,
            monto_retiro: montoRetiro,
            motivo: motivo
        };

        console.log(datosRetiro);
        // Puedes enviar la solicitud axios aquí con los datosRetiro
        axios.post("https://api.meycisstore.com/retiros", datosRetiro,{
            headers:{
                "Content-Type":'application/json',
                Authorization:token
            }
        }).then((response)=>{
            Swal.fire({
                title:'Confirmación',
                text:"Se creo el retiro",
                icon:'success'
            });
            onHide();
        }).catch((error)=>{
            Swal.fire({
                title:'Advertencia',
                text:`Ocurrio un error al crear el retiro:${error.response.data.message}`,
                icon:'warning'
            })
        })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Retiro</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-3' controlId="formRetiro">
                        <Form.Label>Cantidad:</Form.Label>
                        <Form.Control
                            key={'montoRetiro'}
                            type="number"
                            placeholder="Ingrese la cantidad a retirar de la caja"
                            value={montoRetiro}
                            onChange={(e) => setMontoRetiro(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="formMotivo">
                        <Form.Label>Motivo:</Form.Label>
                        <Form.Control
                            key={'motivo'}
                            type="text"
                            placeholder="Ingrese el motivo del retiro"
                            value={motivo}
                            onChange={(e) => setMotivo(e.target.value)}
                        />
                    </Form.Group>
                    <div className='d-flex justify-content-end'>
                        <Button variant="primary" type='submit'>Crear Retiro</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRetiro;
