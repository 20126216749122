
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import CategoriasTable from '../Components/Tables/CategoriasTable';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const CategoriasPage = () => {
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de categorías desde la API
    axios.get('https://api.meycisstore.com/categorias',{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setCategorias(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de categorías:', error);
      });
  }, []);

  return (
    <Card className="h-100">
        <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title>Categorías</Card.Title>
            <Link to="/admin/categorias/nuevo">
            <Button className="bg-meycs" variant="primary">
                Nuevo
            </Button>
            </Link>
        </Card.Header>
      <Card.Body>
        <CategoriasTable categorias={categorias} />
      </Card.Body>
    </Card>
  );
};

export default CategoriasPage;
