import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import DataTable from '../Components/Shared/DataTable';


const InventariosPage = () => {
  const [inventarios, setInventarios] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de inventarios desde la API
    axios.get('https://api.meycisstore.com/inventario/audit', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setInventarios(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de inventarios:', error);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_audit',
      },
      {
        Header: 'Fecha',
        accessor: ({createdAt})=>{ return formatearFecha(createdAt) },
      },
      {
        Header: 'Usuario',
        accessor: ({ User }) => `${User.nombre} ${User.apellidos}`,
      },
      {
        Header: 'Producto',
        accessor: ({ Producto }) => `${Producto.nombre}`,
      },
      {
        Header: 'Talla',
        accessor: ({ Talla }) => `${Talla.nombre}`,
      },
      {
        Header: 'Cantidad Anterior',
        accessor: 'cantidad_previa',
      },
      {
        Header: 'Cantidad Actual',
        accessor: 'cantidad_actual',
      },
      {
        Header: 'Diferencia',
        accessor: 'diferencia',
      },
      {
        Header: 'Motivo',
        accessor: 'motivo',
      },
    ],
    []
  );

  const formatearFecha = (fechaString) => {
    const fecha = new Date(fechaString);
  
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const ampm = horas >= 12 ? 'PM' : 'AM';
    const hora12 = horas % 12 || 12;
  
    return `${dia}/${mes}/${anio} ${hora12}:${minutos}:${segundos} ${ampm}`;
  }

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Movimientos Inventario</Card.Title>
      </Card.Header>
      <Card.Body>
        {
            inventarios ?
                <DataTable columns={columns} data={inventarios} ></DataTable>
            : null
        }
      </Card.Body>
    </Card>
  );
};

export default InventariosPage;
