import React, { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";

import axios from "axios";
import  Notificacion  from "../Shared/Notificacion";
import  LoadingBlock from "../Shared/LoadingBlock";

const LoginPage = () => {

  const { login } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [notificacion, setNotificacion] = useState({
    mostrar: false,
    mensaje: '',
    tipo: 'success',
    duracion: 3000,
  });

  const handleLogin = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);

      // Validaciones
      if (!username.trim()) {
        setError({ username: "El campo de usuario es obligatorio" });
        return;
      }

      if (!password.trim()) {
        setError({ password: "El campo de contraseña es obligatorio" });
        return;
      }

      const response = await axios.post('https://api.meycisstore.com/login', {
        username,
        password,
      });

      const { token, user } = response.data;
      localStorage.setItem('token', token);
      login(user);

      // Redirigir al dashboard o realizar otras acciones después del login
    } catch (error) {
      console.error('Error de autenticación:', error);
      setNotificacion({
        mostrar: true,
        mensaje: 'Credenciales incorrectas. Por favor, inténtalo de nuevo.',
        tipo: 'danger',
        duracion: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleLogin(event);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#dba2b3" }}
    >
      <div
        className="card shadow rounded"
        style={{ width: "30rem", backgroundColor: "#dba2b3" }}
      >
        <div className="card-body">
          <img
            src="/assets/images/logo.jpeg" // Ajusta la ruta según la estructura de tu aplicación
            className="card-img-top mx-auto mb-3 d-flex"
            alt="Logo"
            style={{ maxWidth: "100px", maxHeight: "100px", margin: "auto" }}
          />
          <form>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Usuario
              </label>
              <input
                type="text"
                className={`form-control ${error.username ? "is-invalid" : ""}`}
                id="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setError({ ...error, username: "" });
                }}
              />
              {error.username && (
                <div className="invalid-feedback">{error.username}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Contraseña
              </label>
              <input
                type="password"
                className={`form-control ${error.password ? "is-invalid" : ""}`}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError({ ...error, password: "" });
                }}
                onKeyUp={handleKeyUp} 
              />
              {error.password && (
                <div className="invalid-feedback">{error.password}</div>
              )}
            </div>
            <div className="mb-3 d-flex">
              <button
                type="button"
                className="btn bg-meycs flex-fill"
                onClick={handleLogin}
                disabled={!username || !password}
              >
                Login
              </button>
            </div>
          </form>
          {loading && <LoadingBlock />}
          <Notificacion {...notificacion} onClose={() => setNotificacion({ mostrar: false })} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
