// UsuariosTable.js
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from "axios";
import Swal from 'sweetalert2';

const UsuariosTable = ({ usuarios }) => {
  const navigate = useNavigate();
  const [usersList, setUsers] = useState(usuarios);
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_user',
      },
      {
        Header: 'Nombre',
        accessor: ({ nombre, apellidos }) => `${nombre} ${apellidos}`,
      },
      {
        Header: 'Sucursal',
        accessor: 'sucursal',
      },
      {
        Header: 'Rol',
        accessor: 'rol',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {
          const handleEdit = (usuario) => {
            navigate(`/admin/usuarios/editar/${usuario.id_user}`)
          };
          
          return(
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm"  onClick={() => handleEdit(row.original)}>
                  <FaEdit />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(row.original.id_user)}
                >
                  <FaTrash />
                </Button>
            </div>
          )
        },
      },
    ],
    [navigate]
  );

  useEffect(()=>{
    setUsers(usuarios);
  },[usuarios])

  const handleDelete = (userId) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará el usuario. No podrás deshacer este cambio.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d3a000',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Si el usuario confirma, realizar la eliminación
        axios
          .delete(`https://api.meycisstore.com/users/${userId}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${localStorage.getItem('token')}`,
            },
          })
          .then(() => {
            setUsers((prevUsers) =>
            prevUsers.filter((user) => user.id_user !== userId)
            );
            Swal.fire({
              title: 'Confirmación',
              text: 'El usuario fue eliminado correctamente.',
              icon: 'success',
            });
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'No se logró eliminar el usuario. Por favor, inténtelo nuevamente.',
              icon: 'error',
            });
          });
      }
    });
  };

  return <DataTable columns={columns} data={usersList} />;
};

export default UsuariosTable;
