import React, { useEffect, useMemo,useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios  from "axios";

const SucursalesTable = ({ sucursales }) => {
  const navigate = useNavigate();
  const [sucursalesList, setSucursales] = useState(sucursales); 

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_sucursal',
        width: 25,
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Direccion',
        accessor: 'direccion',
      },
      {
        Header: 'Estatus',
        accessor: 'status',
        width: 50,
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {

          const handleEditSucursal = () => {
            navigate(`/admin/sucursales/editar/${row.original.id_sucursal}`);
          };

          const handleDeleteSucursal = (sucursalId) => {
            Swal.fire({
              title: '¿Estás seguro?',
              text: 'Esta acción eliminará la sucursal. No podrás deshacer este cambio.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d3a000',
              confirmButtonText: 'Sí, eliminar',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                // Si el usuario confirma, realizar la eliminación
                axios.delete(`https://api.meycisstore.com/sucursales/${sucursalId}`, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${localStorage.getItem('token')}`,
                  },
                })
                  .then(() => {
                    setSucursales((prevSucursales) =>
                      prevSucursales.filter((sucursal) => sucursal.id_sucursal !== sucursalId)
                    );
                    Swal.fire({
                      title:'Confirmacion',
                      text:'La Sucursal fue eliminada, el inventario paso a la pagina web',
                      icon: 'success',
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      title:'Error',
                      text:'No se logro eliminar la sucursal, porfavor intentelo nuevamente',
                      icon: 'error',
                    });
                  });
              }
            });
          };

          return (
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm" onClick={handleEditSucursal}>
                <FaEdit />
              </Button>
              <Button variant="danger" size="sm" onClick={() => handleDeleteSucursal(row.original.id_sucursal)}>
                <FaTrash />
              </Button>
            </div>
          );
        },
        width: 50
      },
    ],
    [navigate]
  );

  useEffect(() => {
    setSucursales(sucursales);
  }, [sucursales]);

  return <DataTable columns={columns} data={sucursalesList} />;
};

export default SucursalesTable;
