import { useOutlet } from "react-router-dom";
import TopBar from "../Shared/TopBar";

import Container from 'react-bootstrap/Container';

import { IoIosApps } from "react-icons/io";
import { FaBox,} from 'react-icons/fa'; 
import { Card } from "react-bootstrap";


export const PosLayout = ({children}) => {
    const outlet = useOutlet();

    const menuItems = [
        { label: 'Venta', link: '/pos/venta', icon: <IoIosApps /> },
        { label: 'Productos', link: '/pos/productos', icon: <FaBox /> },
        { label: 'Inventario', link: '/pos/inventario', icon: <FaBox /> },
    ];


    return (
        <div>
            <TopBar items={menuItems} />
            <Container fluid className="vh-100">
                <Card style={{ height: "90vh" }} id="container-pos">
                    {outlet}
                </Card>
            </Container>
        </div>
    );
};
