import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import InventariosTable from '../Components/Tables/InventariosTable';  // Asegúrate de tener el componente InventariosTable creado
import axios from 'axios';


const InventariosPage = () => {
  const [inventarios, setInventarios] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de inventarios desde la API
    axios.get('https://api.meycisstore.com/inventario', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setInventarios(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de inventarios:', error);
      });
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Inventarios</Card.Title>
      </Card.Header>
      <Card.Body>
        <InventariosTable inventarios={inventarios} />
      </Card.Body>
    </Card>
  );
};

export default InventariosPage;
