import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../Shared/DataTable";
import { Button,Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { FaEdit } from "react-icons/fa";
import axios from "axios";

const InventariosTable = ({ inventarios }) => {
    const navigate = useNavigate();
    const [section,setSection] = useState('');

    useEffect( ()=>{
        const user = JSON.parse(localStorage.getItem('user'));
        user.id_rol === 1 ? setSection('admin') : setSection('pos');
    },[] );

    const columns = useMemo(
    () => [
    {
        Header: "ID Inventario",
        accessor: "id_producto",
    },
    {
        Header: "Nombre",
        accessor: "nombre_producto",
    },
    {
        Header: "Inventario Total",
        accessor: "total_cantidad",
        Cell: ({ value, row }) => {
            const handleNoteClick = async (inventario) => {
                try {
                    // Realizar la llamada a la API utilizando Axios
                    const response = await axios
                    .get(`https://api.meycisstore.com/inventario/${inventario.id_producto}`,{
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `${localStorage.getItem('token')}`,
                        },
                    });

                    const columnasInventario = [
                        {
                            Header:"Talla",
                            accessor:"nombre",
                        },
                        {
                            Header:"Cantidad",
                            accessor:"cantidad",
                        }
                    ]
                    
                    const tableRows = response.data.inventario.map((item, index) => (
                        <Row>
                            <h5 className="mb-2"> {item.nombre} </h5>
                            <DataTable columns={columnasInventario} data={item.inventario} searchParam={true} />
                        </Row>
                    ));
                    // Mostrar el resultado en un SweetAlert
                    withReactContent(Swal).fire({
                    icon: "info",
                    title: "Detalle por sucursal",
                    html: <>
                        {tableRows}
                        </>,
                    });
                } catch (error) {
                    // Manejar errores si es necesario
                    console.error("Error al llamar a la API", error);
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al llamar a la API.",
                    });
                }
            };
            return(
                <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleNoteClick(row.original)}
                >
                    {value}
                </span>
                )
        },
    },
    {
        Header: "Acciones",
        accessor: "acciones",
        Cell: ({ row }) => {
            const handleEditInventario = (inventario) => {
            navigate(`/${section}/inventario/editar/${inventario.id_producto}`);
            };

            return (
            <div className="d-flex justify-content-around">
                <Button
                variant="primary"
                size="sm"
                onClick={() => handleEditInventario(row.original)}
                >
                <FaEdit />
                </Button>
            </div>
            );
        },
    },
    ],
    [navigate,section]
    );

    return <DataTable columns={columns} data={inventarios} />;
};

export default InventariosTable;
