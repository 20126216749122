import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const SucursalesFormEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [sucursal, setSucursal] = useState({
    nombre: "",
    direccion: "",
  });

  function goBack() {
    navigate(-1);
}

  const [tocado, setTocado] = useState({
    nombre: false,
    direccion: false,
  });

  useEffect(() => {
    // Cargar los datos de la sucursal para editar
    const token = localStorage.getItem('token');
    if (!token) {
      Swal.fire({
        title:'Validacion',
        text:'No se encontró un token de autorización. La sesion ha terminado',
        icon:'error'
      });
      return;
    }

    axios.get(`https://api.meycisstore.com/sucursales/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        const { nombre, direccion } = response.data;
        setSucursal({ nombre, direccion });
      })
      .catch(error => {
        console.error('Error al obtener datos de la sucursal:', error);
        Swal.fire({
          title:'Validacion',
          text:`Error al obtener datos de la sucursal: ${error}`,
          icon:'error'
        });
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSucursal({ ...sucursal, [name]: value });
    setTocado({ ...tocado, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar campos antes de enviar
    if (!sucursal.nombre || !sucursal.direccion) {
      Swal.fire({
        title:'Validacion',
        text:'Todos los campos son obligatorios',
        icon:'warning'
      });
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      Swal.fire({
        title:'Validacion',
        text:'No se encontró un token de autorización. La sesion ha terminado',
        icon:'error'
      });
      return;
    }

    axios.put(`https://api.meycisstore.com/sucursales/${id}`, sucursal, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then(response => {
        Swal.fire({
          title: 'Confirmacion',
          text: 'Se actualizó la sucursal correctamente',
          icon: 'success',
        }).then((result) => {
          navigate("/admin/sucursales");
        });
      })
      .catch(error => {
        Swal.fire({
          title:'Validacion',
          text:`Error al actualizar la sucursal: ${error}`,
          icon:'error'
        });
      });
  };

  return (
    <Card className="h-100">
      <Card.Header>Editar Sucursal</Card.Header>
      <Card.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                name="nombre"
                value={sucursal.nombre}
                onChange={handleChange}
                isInvalid={tocado.nombre && !sucursal.nombre}
              />
              <Form.Control.Feedback type="invalid">
                El nombre es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDireccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese la dirección"
                name="direccion"
                value={sucursal.direccion}
                onChange={handleChange}
                isInvalid={tocado.direccion && !sucursal.direccion}
              />
              <Form.Control.Feedback type="invalid">
                La dirección es obligatoria.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                Guardar Cambios
              </Button>
              <Button variant="danger" className="ms-2" onClick={goBack} >
                Regresar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default SucursalesFormEdit;
