import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const [loginTime, setLoginTime] = useLocalStorage("loginTime", null);
  const navigate = useNavigate();

  const value = useMemo(
    () => {
      const login = async (data) => {
        setUser(data);
        console.log(data);
        data.id_rol === 1 ? 
          navigate("/admin/dashboard", { replace: true }) :
          navigate("/pos/inicio", { replace: true }) ;
      };

      const logout = () => {
        setUser(null);
        setLoginTime(null);
        navigate("/", { replace: true });
      };

      const isTokenExpired = () => {
        if (!loginTime) {
          return true; // No se ha iniciado sesión
        }

        //const expirationTime = loginTime + 8 * 60 * 60 * 1000; // 8 horas en milisegundos
        const expirationTime = loginTime + 0 * 0 * 1 * 1000; // 8 horas en milisegundos
        const currentTime = new Date().getTime();
        console.log(expirationTime);
        console.log(currentTime);
        console.log(currentTime > expirationTime);

        return currentTime > expirationTime;
      };

      return ({
        user,
        login,
        logout,
        isTokenExpired
      })
    },
    [user,loginTime,navigate,setUser,setLoginTime]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
