import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import ProductosTable from '../Components/Tables/ProductosTable';  // Asegúrate de tener el componente ProductosTable creado
import axios from 'axios';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ProductosPage = () => {
  const [productos, setProductos] = useState([]);
  const location = useLocation();
  const endpoint = location.pathname.split('/').pop()

  useEffect(() => {
    // Simulación de carga de datos de productos desde la API
    axios.get(`https://api.meycisstore.com/${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setProductos(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de productos:', error);
      });
  }, [endpoint]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Productos</Card.Title>
        <Link to="/admin/productos/nuevo">
          {
            endpoint === 'productos' ?  
            (<Button className="bg-meycs" variant="primary">
              Nuevo
            </Button>) : null
          }
        </Link>
      </Card.Header>
      <Card.Body>
        <ProductosTable productos={productos} />
      </Card.Body>
    </Card>
  );
};

export default ProductosPage;
