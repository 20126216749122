import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaInfo } from 'react-icons/fa';

const VentasTable = ({ ventas,fechaInicio,fechaFin }) => {
  const navigate = useNavigate();
  const [ventasList, setVentas] = useState(ventas);

  const columns = useMemo(
    () => [
      {
        Header: 'Sucursal',
        accessor: 'Sucursal.nombre',
        width: 25,
      },
      {
        Header: 'Total Venta',
        accessor: 'total_monto',
      },
      {
        Header: 'Ganancia',
        accessor: 'total_utilidad',
      },
      {
        Header: 'Descuento Total',
        accessor: 'total_descuento',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {
          // Puedes agregar lógica para acciones específicas de ventas si es necesario
          const handleEditVenta = (id_sucursal) => {
            localStorage.setItem('fechaInicio',fechaInicio);
            localStorage.setItem('fechaFin',fechaFin);
            navigate(`/admin/ventas/sucursal/${id_sucursal}`);
          };

          return (
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm" onClick={() => handleEditVenta(row.original.id_sucursal)}>
                <FaInfo />
              </Button>
            </div>
          );
        },
        width: 50,
      },
    ],
    [navigate,fechaInicio,fechaFin]
  );


  useEffect(() => {
    setVentas(ventas);
  }, [ventas]);

  return <DataTable columns={columns} data={ventasList} searchParam={true} />;
};

export default VentasTable;
