import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import VentasTable from '../Components/Tables/VentasTable';  // Asegúrate de tener el componente VentasTable creado
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import { isValid, isAfter,set,isEqual } from 'date-fns';
import Swal from 'sweetalert2';

const VentasPage = () => {

  const [ventas, setVentas] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const validateDates = (startDate, endDate) => {
    const startDateWithoutTimezone = set(startDate, { timeZone: 'UTC' });
    const endDateWithoutTimezone = set(endDate, { timeZone: 'UTC' });

    if( isEqual(startDateWithoutTimezone,endDateWithoutTimezone) ) 
    {
      return true;
    }

    if (!isValid(startDateWithoutTimezone) || !isValid(endDateWithoutTimezone) || (isAfter(endDateWithoutTimezone, startDateWithoutTimezone) === false) ) {
      // Mostrar mensaje de error con Swal
      Swal.fire({
        title: 'Error',
        text: 'Las fechas no son congruentes. Por favor, selecciona fechas válidas.',
        icon: 'error',
      });

      setSelectedDate(new Date());
      setSelectedEndDate(new Date());
      return false;
    }
  
    // Las fechas son congruentes
    return true;
  };

  const obtenerFechaString = (fechaInit)=>{
    const fecha = new Date(fechaInit);
    const offset = fecha.getTimezoneOffset();
    fecha.setMinutes(fecha.getMinutes() - offset);
    return fecha.toISOString().split('T')[0];
  }

  useEffect(() => {
    const isValidDates = validateDates(selectedDate, selectedEndDate);
    if(isValidDates)
    {
      const fetchRegistrosVentas = async () => {
        try {
          const fechaInicialString = obtenerFechaString(selectedDate);
          const fechaFinalString = obtenerFechaString(selectedEndDate);
          const response = await axios.get('https://api.meycisstore.com/ventas', {
              params:{
                fechaInicio: fechaInicialString ,
                fechaFin:fechaFinalString
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${localStorage.getItem('token')}`,
              },
            })
          setVentas(response.data);
        } catch (error) {
          console.error('Error al obtener datos del checador:', error);
        }
      };
      fetchRegistrosVentas();
    }
  }, [selectedDate,selectedEndDate]);

  const handleDateChange = (date) => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    setSelectedDate(adjustedDate);
  };

  const handleEndDateChange = (date) => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    setSelectedEndDate(adjustedDate);
  };

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Ventas</Card.Title>
        <div className="d-flex">
          <Form.Group className="mr-2">
            <Form.Label>Fecha Inicio</Form.Label>
            <ReactDatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="mr-2">
            <Form.Label>Fecha Fin</Form.Label>
            <ReactDatePicker
              selected={selectedEndDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </Form.Group>
        </div>
      </Card.Header>
      <Card.Body>
        <VentasTable ventas={ventas} fechaInicio={selectedDate} fechaFin={selectedEndDate} />
      </Card.Body>
    </Card>
  );
};

export default VentasPage;
