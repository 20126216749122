import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FaUser,FaLock,FaSignOutAlt,FaCashRegister, FaKey } from 'react-icons/fa'; 
import { useNavigate } from "react-router-dom";
import  axios  from "axios";
import Swal from 'sweetalert2';

const TopBar = ({items}) => {

  const [seccion, setSeccion] = useState("");
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const handleLogout = async () => {
    axios.post('https://api.meycisstore.com/logout',user, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${localStorage.getItem('token')}`,
        },
      }).then((response)=>{
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate("/");
      }).catch((error)=>{
        Swal.fire({
          title:'Advertencia',
          text:error.response.data.message,
          icon:'warning'
        })
      });;
  };

  useEffect(()=>{
    const verificarUsuario = async () => {
      axios.post(`https://api.meycisstore.com/verifyUser/${user.id_rol}`,user, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`${localStorage.getItem('token')}`,
          },
        }).then((response)=>{
          user.id_rol === 1 ? setSeccion("admin") : setSeccion("pos");
        }).catch((err)=>{
          Swal.fire({
            title:'Error',
            text:err.response.data.message,
            icon:'error'
          }).then(async ()=>{
            axios.post('https://api.meycisstore.com/logout',user, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization':`${localStorage.getItem('token')}`,
              },
            }).then(()=>{
              localStorage.removeItem('user');
              localStorage.removeItem('token');
              navigate("/");
            });
          }).catch((error)=>{
            Swal.fire({
              title:'Advertencia',
              text:err.response.data.message,
              icon:'warning'
            })
          });
        });
    };
    verificarUsuario();
  },[user,navigate])

  return (
    <Navbar bg="light" expand="lg" className="meycis-bd-color mb-3">
      <Navbar.Brand as={Link} to={`/${seccion}/dashboard`}>
        <img
          src="/assets/images/logo.jpeg"
          alt="Logo"
          className="d-inline-block align-top mx-3"
          style={{ maxHeight: '30px' }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between'>
        <Nav className="mr-auto">
          {items.map((item, index) => (
            item.subItems ? (
              <NavDropdown
                key={index}
                title={item.label}
                id={`dropdown-${index}`}
                className="text-light"
              >
                {item.subItems.map((subItem, subIndex) => (
                  <NavDropdown.Item key={subIndex} as={Link} to={subItem.link}>{subItem.label} </NavDropdown.Item>
                ))}
              </NavDropdown>
            ) : (
              <Nav.Link key={index} as={Link} to={item.link} className='text-light'>
                {item.label}
              </Nav.Link>
            )
          ))}
        </Nav>
        <Nav className="px-5">
          <NavDropdown title={<span className="text-white"><FaUser /> { user.nombre } { user.apellido }</span>} id="basic-nav-dropdown">
            {
              user.id_rol === 1 && location.pathname.split('/')[1] === 'admin' ? <NavDropdown.Item as={Link} to={'/pos/inicio'}>
                <FaCashRegister /> Punto de Venta
              </NavDropdown.Item> : null
            }
            {
              user.id_rol === 1 && location.pathname.split('/')[1] === 'pos' ? <NavDropdown.Item as={Link} to={'/admin/dashboard'}>
                <FaKey /> Panel administrador
              </NavDropdown.Item> : null
            }
            <NavDropdown.Item as={Link} to={'/admin/cambiar-contrasena'}>
              <FaLock /> Cambiar Contraseña
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleLogout}>
              <FaSignOutAlt /> Cerrar Sesión
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopBar;
