import React, { useEffect, useState } from 'react';
import { Card,Button } from 'react-bootstrap';
import VentasDetalleTable from '../Components/Tables/VentasDetalleTable';  // Asegúrate de tener el componente VentasTable creado
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const VentasSucursalPage = () => {
  const [ventas, setVentas] = useState([]);
  const {id} = useParams();

  const obtenerFechaString = (fechaInit)=>{
    const fecha = new Date(fechaInit);
    const offset = fecha.getTimezoneOffset();
    fecha.setMinutes(fecha.getMinutes() - offset);
    return fecha.toISOString().split('T')[0];
  }

  useEffect(() => {
    // Simulación de carga de datos de ventas desde la API
    const fechaInicio = new Date(localStorage.getItem('fechaInicio')).toISOString();
    const fechaFin = new Date( localStorage.getItem('fechaFin')).toISOString() ;
    
    const fechaInicialString = obtenerFechaString(fechaInicio);
    const fechaFinalString = obtenerFechaString(fechaFin);

    axios.get(`https://api.meycisstore.com/ventas/sucursal/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
      params:{
        fechaInicio:fechaInicialString,
        fechaFin:fechaFinalString
      }
    })
      .then(response => {
        setVentas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de ventas:', error);
      });
  }, [id]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Detalle Ventas Sucursal </Card.Title>
        <Link to="/admin/ventas">
          <Button variant="danger">
            Regresar
          </Button>
        </Link>
      </Card.Header>
      <Card.Body>
        <VentasDetalleTable ventas={ventas} />
      </Card.Body>
    </Card>
  );
};

export default VentasSucursalPage;
