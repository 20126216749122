import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { FaInfo,FaPrint } from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const VentasTable = ({ ventas }) => {
  const [ventasList, setVentas] = useState(ventas);

  const columns = useMemo(
    () => [
      {
        Header: 'ID Venta',
        accessor: 'id_venta',
        width: 10,
      },
      {
        Header:'Fecha',
        accessor:'fecha',
        width: 10,
      },
      {
        Header: 'Cliente',
        accessor: ({ Cliente }) => `${Cliente.nombre} ${Cliente.apellidos}`,
        width: 50,
      },
      {
        Header: 'Vendedor',
        accessor: ({ User }) => `${User.nombre} ${User.apellidos}`,
        width: 50,
      },
      {
        Header: 'Total Venta',
        accessor: 'monto_total',
        width: 25,
      },
      {
        Header: 'Ganancia',
        accessor: 'total_utilidad',
        width: 25,
      },
      {
        Header: 'Descuento Total',
        accessor: 'total_descuento',
        width: 25,
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {
          // Puedes agregar lógica para acciones específicas de ventas si es necesario
          const handleDetailVenta = (detalles) => {
            const columnasDetalle = [
                {
                    Header:"Nombre",
                    accessor:"Producto.nombre",
                },
                {
                    Header:"Talla",
                    accessor:"Talla.nombre",
                },
                {
                    Header:"Cant.",
                    accessor:"cantidad",
                },
                {
                    Header:"Precio",
                    accessor:"precio_unitario",
                },
                {
                    Header:"Sub.",
                    accessor:"subtotal",
                }
            ]

            // Mostrar el resultado en un SweetAlert
            withReactContent(Swal).fire({
            icon: "info",
            title: "Detalle Venta",
            html: <DataTable columns={columnasDetalle} data={detalles} searchParam={true} />,
            });
          };

          const handlePrintlVenta = () =>{
            Swal.fire({
                title: 'Error',
                text: `No se detecta la impresora. Favor de verificar las conexiones de la impresora`,
                icon: 'error',
              });
          };

          return (
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm" onClick={() => handleDetailVenta(row.original.DetalleVentas)}>
                <FaInfo />
              </Button>
              <Button variant="warning" size="sm" onClick={() => handlePrintlVenta()}>
                <FaPrint />
              </Button>
            </div>
          );
        },
        width: 50,
      },
    ],
    []
  );


  useEffect(() => {
    setVentas(ventas);
  }, [ventas]);

  return <DataTable columns={columns} data={ventasList} searchParam={true} />;
};

export default VentasTable;
