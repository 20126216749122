import { useOutlet } from "react-router-dom";
import TopBar from "../Shared/TopBar";
import SideBar from '../Shared/SideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { IoIosApps } from "react-icons/io";
import { BiDollar,BiMoney } from "react-icons/bi";
import { CiInboxOut } from "react-icons/ci";
import { FaUsers,FaListAlt,FaBox,FaClipboardList,FaAddressBook,FaWarehouse,FaBuilding,FaClock,FaTshirt,FaInternetExplorer, FaFolder} from 'react-icons/fa'; 
import { AiOutlineAudit } from "react-icons/ai";

export const ProtectedLayout = ({children}) => {
    const outlet = useOutlet();

    const menuItems = [
        { label: 'Dashboard', link: '/admin/dashboard', icon: <IoIosApps /> },
        { label: 'Menu Administrativo', link: '/admin/dashboard', icon: <FaFolder />, subItems:[
            { label: 'Sucursales', link: '/admin/sucursales', icon: <FaBuilding /> },
            { label: 'Ventas', link: '/admin/ventas', icon: <BiDollar /> },
            { label: 'Clientes', link: '/admin/clientes', icon: <FaAddressBook /> },
            { label: 'Cortes de Caja', link: '/admin/cortes-caja', icon: <BiMoney /> },
            { label: 'Retiros', link: '/admin/retiros', icon: <CiInboxOut /> },
        ] },
        //{ label: 'Promociones', link: '/promociones', icon: <FaPercent /> },
        //{ label: 'Órdenes', link: '/admin/ordenes', icon: <FaShoppingCart /> },
        { label: 'Menu Inventario', link: '/admin/inventario', icon: <FaWarehouse />, subItems:[
            { label: 'Categorías', link: '/admin/categorias', icon: <FaListAlt /> },
            { label: 'Productos', link: '/admin/productos', icon: <FaBox /> },
            { label: 'Tallas', link: '/admin/tallas', icon: <FaTshirt /> },
            { label: 'Inventario', link: '/admin/inventario', icon: <FaWarehouse /> },
            { label: 'Movimientos Inventario', link: '/admin/inventario/audit', icon: <AiOutlineAudit /> },
            { label: 'Productos Pendientes', link: '/admin/productos-pendientes', icon: <FaClipboardList /> },
        ] },
        { label: 'Menu Usuarios', link: '/admin/usuarios', icon: <FaUsers />,subItems:[
            { label: 'Usuarios', link: '/admin/usuarios', icon: <FaUsers />},
            { label: 'Checador', link: '/admin/usuarios/checador', icon: <FaClock /> }
        ] },
        { label: 'Pagina Web', link:'/admin/paginaweb',icon:<FaInternetExplorer />}
    ];


    return (
        <div>
            {/* Top Bar */}
            <TopBar items={menuItems} />
            <Container fluid className="vh-100">
                <Row>
                    {/* Sidebar Column */}
                    <Col md={2} className="d-md-block d-none">
                    <SideBar items={menuItems} />
                    </Col>
                    {/* Main Content Column */}
                    <Col md={10}>
                        {outlet}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
