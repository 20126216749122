import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ClientesTable from '../Components/Tables/ClientesTable';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ClientesPage = () => {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    // Simulación de carga de datos de categorías desde la API
    axios.get('https://api.meycisstore.com/clientes',{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(response => {
        setClientes(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de categorías:', error);
      });
  }, []);

  return (
    <Card className="h-100">
        <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title>Clientes</Card.Title>
            <Link to="/admin/clientes/nuevo">
            <Button className="bg-meycs" variant="primary">
                Nuevo
            </Button>
            </Link>
        </Card.Header>
      <Card.Body>
        <ClientesTable clientes={clientes} />
      </Card.Body>
    </Card>
  );
};

export default ClientesPage;
