import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';

const ModalBuscarProducto = ({ show, onHide, onAddProduct }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))

  // Función para simular la búsqueda de productos
  const searchProducts = (tmpSearch) => {
    if (!searchTerm) {
      return;
    }
    const token = localStorage.getItem('token');
    axios.get('https://api.meycisstore.com/productos/search',{
        headers:{
            "Content-Type":"application/json",
            "Authorization":`${token}`
        },
        params:{
            id_sucursal:user.id_sucursal,
            nombre:tmpSearch
        }
    }).then(results=>{
        setSearchResults(results.data)
    }).catch((error)=>{
      console.log(error);
    })

  };

  // Función para manejar el cambio en el input
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Realizar la búsqueda automáticamente si hay más de 3 caracteres
    if (value.length >= 3) {
      searchProducts(value);
    } else {
      setSearchResults([]);
    }
  };

  // Función para manejar el clic en el botón "Añadir"
  const handleAddClick = (producto) => {
    // Puedes realizar acciones adicionales antes de cerrar el modal
    setSearchTerm('');
    setSearchResults([]);
    onAddProduct(producto);
    onHide();
  };

  const beforeHide = () =>{
    setSearchTerm('');
    setSearchResults([]);
    onHide();
  }

  return (
    <Modal show={show} onHide={beforeHide}>
      <Modal.Header closeButton>
        <Modal.Title>Buscar Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e)=>{ e.preventDefault();}}>
          <Form.Group controlId="formSearch">
            <Form.Label>Buscar Producto:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre del producto"
              value={searchTerm}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>

        {searchResults.length > 0 && (
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Imagen</th>
                <th>Talla</th>
                <th>Existencia</th>
                <th>Precio</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((producto) => (
                <tr key={ `${producto.id_producto}_${ producto.id_talla}`}>
                  <td className='text-center'>
                    {
                        producto.imagen ? 
                          <img width={'50px'} src={producto.imagen} alt="" />
                        :
                          <img width={'50px'} src="https://9931890f7c.cbaul-cdnwnd.com/d04f2c1531f2b3361e7500a0d9ec6946/200000077-87966888e4/Imagen-no-disponible.png?" alt="" />
                    }
                  </td>
                  <td>{producto.nombre_producto}</td>
                  <td>{producto.nombre_talla}</td>
                  <td>{producto.cantidad}</td>
                  <td>{producto.precio_venta}</td>
                  <td>
                    <Button variant="primary" onClick={() => handleAddClick(producto)}>
                      Añadir
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalBuscarProducto;
