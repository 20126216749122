import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import axios from "axios";
import { useParams,useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const EditarUsuarioForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [sucursales, setSucursales] = useState([]);

  const [usuario, setUsuario] = useState({
    id: "",
    nombre: "",
    apellidos: "",
    id_sucursal: "",
    id_rol: "",
    email: "",
  });

  const [tocado, setTocado] = useState({
    nombre: false,
    apellidos: false,
    email: false,
    sucursal: false,
    rol: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
    setTocado({ ...tocado, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!token) {
      Swal.fire({
        title:'Validación',
        text:'No se encontró un token de autorización',
        icon:'warning'
      });
      return;
    }

    axios.put(`https://api.meycisstore.com/users/${id}`, usuario,
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
    })
      .then(response => {
        Swal.fire({
          title:'Confirmacion',
          text:`Se edito el usuario correctamente`,
          icon:'success'
        }).then(()=>{
          navigate('/admin/usuarios');
        });
      })
      .catch(error => {
        Swal.fire({
          title:'Confirmacion',
          text:`Error al editar el usuario:${error}`,
          icon:'error'
        });
      });
  };

  useEffect(() => {
    const fetchSucursales=()=>{
      const token = localStorage.getItem('token');
      axios.get('https://api.meycisstore.com/sucursales',{
        headers:{
          "Content-Type":'application/json',
          Authorization:token
        }
      }).then((response)=>{
        setSucursales(response.data);
      }).catch((error)=>{
        Swal.fire({
          title:'Advertencia',
          text:`Ocurrio un error al obtener las sucursales:${error.response.data.message}`,
          icon:'warning'
        })
      })
    }
    fetchSucursales();
    // Obtener el parámetro "id" de la URL
    const userId = id;

    // Realizar una solicitud GET para obtener la información del usuario
    axios.get(`https://api.meycisstore.com/users/${userId}`,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
    })
      .then(response => {
        // Filtrar la información del usuario para no incluir contraseñas
        const { contrasena, confirmarContrasena, ...userInfo } = response.data;
        setUsuario(userInfo);
      })
      .catch(error => {
        Swal.fire({
          title:'Confirmacion',
          text:`Error al obtener datos del usuario:${error}`,
          icon:'error'
        });
      });
  }, [id,token]); 

    function goBack() {
        navigate(-1);
    }
  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title>Editar usuario</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                name="nombre"
                value={usuario.nombre}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formApellidos">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese los apellidos"
                name="apellidos"
                value={usuario.apellidos}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="mail"
                placeholder="Ingrese un correo electrónico"
                name="email"
                value={usuario.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSucursal">
              <Form.Label>Sucursal</Form.Label>
              <Form.Control
                as="select"
                name="id_sucursal"
                value={usuario.id_sucursal}
                onChange={handleChange}
              >
                <option>Seleccionar sucursal...</option>
                {sucursales.map((sucursal) => (
                    <option key={sucursal.id_sucursal} value={sucursal.id_sucursal}>
                    {sucursal.nombre}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRol">
              <Form.Label>Rol</Form.Label>
              <Form.Control
                as="select"
                name="rol"
                value={usuario.id_rol}
                onChange={handleChange}
              >
                <option>Seleccionar rol...</option>
                <option value="1">Administrador</option>
                <option value="2">Supervisor</option>
                <option value="3">Vendedor</option>
              </Form.Control>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" disabled={!usuario.nombre || !usuario.apellidos || !usuario.email  || !usuario.id_sucursal || !usuario.id_rol}>
                Guardar Cambios
              </Button>
              <Button variant="danger" className="ms-2" onClick={goBack} >
                Regresar
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default EditarUsuarioForm;
