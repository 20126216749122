import React, { useEffect, useState } from 'react';
import { Form,Button } from 'react-bootstrap';
import axios from "axios";
import Swal from 'sweetalert2';

const ComoComprarForm = () =>{
    const [images,setImages] = useState();
    const [banner,setBanner] = useState(null);

    useEffect(()=>{
        obtenerImagenComoComprar();
    },[])

    const obtenerImagenComoComprar = ()=>{
        axios.get("https://api.meycisstore.com/configuracion/comocomprar", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            setImages(response.data);
        })
        .catch(error => {
            const errorMessage = `Error al obtener los banner: ${error}`;
            Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
            });
        });
    }

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(!banner)
        {
            Swal.fire({
                title:'Validacion',
                text: 'Es necesario seleccionar una imagen',
                icon:'error'
            });
            return;
        }

        const formData = new FormData();
        formData.append('imagen', banner);
        axios.post("https://api.meycisstore.com/comocomprar", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            setBanner(null);
            obtenerImagenComoComprar();
            const successMessage = 'Se actualizo la imagen correctamente';
            Swal.fire({
            title: 'Confirmacion',
            text: successMessage,
            icon: 'success',
            })
        })
        .catch(error => {
            const errorMessage = `Error al actualizar la imagen: ${error}`;
            Swal.fire({
            title:'Validacion',
            text: errorMessage,
            icon:'error'
            });
        });
    }
    
    const handleChange = (e) =>{
        const { type,files } = e.target;
        if (type === 'file') {
            // Manejar la carga de imagen
            if (files.length > 0) {
                setBanner(files[0]);
            }
        } 
    }

    return(
        <Form className='mt-3' onSubmit={handleSubmit}>
            <p>En esta seccion puedes colocar la imagen que le ayudara a saber a tus clientes como comprar, procura que sea <strong>rectangular</strong>.</p>
            <div className="mt-3 border p-3 mb-3">
            {
                images ? (
                    <div className='d-flex' >
                        <div className='m-2' style={{ position: 'relative', display: 'inline-block' }}>
                                <img width={400} src={images.valor} alt='imagen como comprar' />
                        </div>
                    </div>
                ) : (
                    <p>SIN IMÁGENES PARA MOSTRAR</p>
                )
            }
            </div>
            <div className="col-md-4">
                <Form.Group className="mb-3" controlId="formImagen">
                    <Form.Label>Imagen</Form.Label>
                    <Form.Control
                        type="file"
                        name="imagen"
                        onChange={handleChange}
                    />
                </Form.Group>
                {banner && (
                    <img src={URL.createObjectURL(banner)} alt="Imagen seleccionada" style={{ maxWidth: '150px', height: 'auto' }} />
                    
                )}
                <div className="d-flex justify-content-end">
                    <Button variant="primary" type="submit" >
                        Guardar Imagen
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default ComoComprarForm;