import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button, Table, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import DataTable from '../Shared/DataTable';
import withReactContent from 'sweetalert2-react-content';

const ModalInventario = ({ show, onHide }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))

  // Función para simular la búsqueda de productos
  const searchProducts = () => {
    if (!searchTerm) {
      return;
    }
    const token = localStorage.getItem('token');
    axios.get('https://api.meycisstore.com/productos/search',{
        headers:{
            "Content-Type":"application/json",
            "Authorization":`${token}`
        },
        params:{
            id_sucursal:user.id_sucursal,
            nombre:searchTerm
        }
    }).then(results=>{
        setSearchResults(results.data)
    }).catch((error)=>{
      Swal.fire({
        icon:'info',
        title:'Informacion',
        text:error.response.data.message
      });
      setSearchResults([]);
    })

  };

  // Función para manejar el cambio en el input
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Realizar la búsqueda automáticamente si hay más de 3 caracteres
    if (value.length >= 3) {
      searchProducts();
    } else {
      setSearchResults([]);
    }
  };

  const beforeHide = () =>{
    setSearchResults([]);
    setSearchTerm('');
    onHide();
}


  // Función para manejar el clic en el botón "Añadir"
  const handleAddClick = async (producto) => {
    try {
        // Realizar la llamada a la API utilizando Axios
        const response = await axios
        .get(`https://api.meycisstore.com/inventario/${producto.id_producto}`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${localStorage.getItem('token')}`,
            },
        });

        const columnasInventario = [
            {
                Header:"Talla",
                accessor:"nombre",
            },
            {
                Header:"Cantidad",
                accessor:"cantidad",
            }
        ]
        
        const tableRows = response.data.inventario.map((item, index) => (
            <Row>
                <h5 className="mb-2"> {item.nombre} </h5>
                <DataTable columns={columnasInventario} data={item.inventario} searchParam={true} />
            </Row>
        ));
        // Mostrar el resultado en un SweetAlert
        withReactContent(Swal).fire({
        icon: "info",
        title: "Detalle por sucursal",
        html: <>
            {tableRows}
            </>,
        });
    } catch (error) {
        // Manejar errores si es necesario
        console.error("Error al llamar a la API", error);
        Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al llamar a la API.",
        });
    }
  };

  return (
    <Modal show={show} onHide={beforeHide}>
      <Modal.Header closeButton>
        <Modal.Title>Inventario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e)=>{ e.preventDefault();}} >
          <Form.Group controlId="formSearch">
            <Form.Label>Buscar Producto:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre del producto"
              value={searchTerm}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>

        {searchResults.length > 0 && (
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((producto) => (
                <tr key={ `${producto.id_producto}_${ producto.id_talla}`}>
                  <td>{producto.nombre_producto}</td>
                  <td>
                    <Button variant="primary" onClick={() => handleAddClick(producto)}>
                        Ver inventario
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalInventario;
