import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from "axios";
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const TerminosServicio = () =>{
    const [valor, setValor] = useState({ valor: "", clave: "terminosservicio" });
    const [actual, setActual] = useState({});

    useEffect(() => {
        obtenerValor();
    }, []);

    const obtenerValor = () => {
        axios.get("https://api.meycisstore.com/configuracion/terminosservicio", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            if (response.data) {
                setActual(response.data);
            }
        })
        .catch(error => {
            const errorMessage = `Error al obtener los Terminos del Servicio: ${error}`;
            Swal.fire({
                title: 'Validacion',
                text: errorMessage,
                icon: 'error'
            });
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        valor.valor.trimEnd();
        if (!valor.valor) {
            Swal.fire({
                title: 'Validacion',
                text: 'El texto no puede estar vacio',
                icon: 'error'
            });
            return;
        }

        axios.post("https://api.meycisstore.com/configuracion", valor, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
        })
        .then(response => {
            obtenerValor();
            const successMessage = 'Se actualizaron los terminos del servicio correctamente';
            Swal.fire({
                title: 'Confirmacion',
                text: successMessage,
                icon: 'success',
            });
        })
        .catch(error => {
            const errorMessage = `Error al colocar los terminos del servicio: ${error}`;
            Swal.fire({
                title: 'Validacion',
                text: errorMessage,
                icon: 'error'
            });
        });
    }
    
    return(
        <Form className='mt-3' onSubmit={handleSubmit}>
            <div>
                En esta seccion puedes controlar el texto que aparece en la pagina de <strong>Terminos y Condiciones</strong> asegurate de ser muy clara en los Terminos del servicio para comunicarselo a los clientes.
            </div>
            <div className="mt-3 border p-3 mb-3">
                {
                    actual.valor ? <div dangerouslySetInnerHTML={{ __html: actual.valor }} /> : <p>NO HAY TEXTO PARA MOSTRAR</p>
                }
            </div>
            <Form.Group className="mb-3" controlId="formImagen">
                <Form.Label>Terminos de Servicio</Form.Label>
                <ReactQuill
                    value={valor.valor}
                    onChange={(value) => setValor(prevState => ({ ...prevState, valor: value }))}
                />
            </Form.Group>
            <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                    Guardar
                </Button>
            </div>
        </Form>
    );
}

export default TerminosServicio;
