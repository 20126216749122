import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaInfo } from 'react-icons/fa';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const RetirosTable = ({ retiros }) => {
  const [retirosList, setRetiros] = useState(retiros);

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'fecha',
        Cell:({row})=>{
          return row.original.fecha.split('T')[0]
        }
      },
      {
        Header: 'Usuario',
        accessor: 'User.nombre',
        Cell:({row})=>{
          return `${row.original.User.nombre} ${row.original.User.apellidos} `
        }
      },
      {
        Header: 'Total Retiro',
        accessor: 'monto_retiro',
      },
      {
        Header: 'Motivo',
        accessor: 'motivo',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {
          const handleViewRetiro = (retiro) => {
            const fecha = new Date(retiro.fecha);
            const detalleRetiro = 
            <Form>
              <Row>
                <Col className='text-start'>
                  <Form.Label>Fecha y Hora</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="nombre"
                    value={`${fecha.toLocaleDateString()} ${fecha.toLocaleTimeString()} `}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col className='text-start'>
                  <Form.Label>Nombre</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="nombre"
                    value={retiro.Sucursal.nombre}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col className='text-start'>
                  <Form.Label>Nombre Vendedor</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="nombre"
                    value={`${retiro.User.nombre} ${retiro.User.apellidos} `}
                    disabled
                  />
                </Col>
              </Row>
              <Row className='text-start'>
                <Col>
                  <Form.Label>Motivo</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="nombre"
                    value={retiro.motivo}
                    disabled
                  />
                </Col>
              </Row>
              <Row className='text-start'>
                <Col>
                  <Form.Label>Cantidad Retiro</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="nombre"
                    value={retiro.monto_retiro}
                    disabled
                  />
                </Col>
              </Row>
            </Form>;

            withReactContent(Swal).fire({
              icon:'info',
              title:'Detalle Retiro',
              html:<>
                    {
                      detalleRetiro
                    }
                  </>
            })
          };

          return (
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm" onClick={() => handleViewRetiro(row.original)}>
                <FaInfo />
              </Button>
            </div>
          );
        },
        width: 50,
      },
    ],
    []
  );

  useEffect(() => {
    setRetiros(retiros);
  }, [retiros]);

  return <DataTable columns={columns} data={retirosList} searchParam={true} />;
};

export default RetirosTable;
