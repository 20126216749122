import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../Shared/DataTable';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaInfo } from 'react-icons/fa';

const CortesTable = ({ cortes }) => {
  const navigate = useNavigate();
  const [cortesList, setCortes] = useState(cortes);

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'fecha',
      },
      {
        Header: 'Total Ventas',
        accessor: 'num_ventas',
      },
      {
        Header: 'Total Retiros',
        accessor: 'total_retiros',
      },
      {
        Header: 'D. Efectivo',
        accessor: 'diferencia_efectivo',
        Cell:({row})=>{
          return row.original.diferencia_efectivo ? 
            parseFloat(row.original.diferencia_efectivo).toFixed(2):
            "0.00";
        }
      },
      {
        Header: 'D. Tarjetas',
        accessor: 'diferencia_tarjeta',
        Cell:({row})=>{
          return row.original.diferencia_tarjeta ? 
            parseFloat(row.original.diferencia_tarjeta).toFixed(2):
            "0.00";
        }
      },
      {
        Header: 'Tipo Corte',
        accessor: 'tipo_corte',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => {
          const handleViewCorte = (id_corte) => {
            navigate(`/admin/cortes/${id_corte}`);
          };

          return (
            <div className="d-flex justify-content-around">
              <Button variant="primary" size="sm" onClick={() => handleViewCorte(row.original.id_corte)}>
                <FaInfo />
              </Button>
            </div>
          );
        },
        width: 50,
      },
    ],
    [navigate]
  );

  useEffect(() => {
    setCortes(cortes);
  }, [cortes]);

  return <DataTable columns={columns} data={cortesList} searchParam={true} />;
};

export default CortesTable;
